import {
    CustomerClientVehicleV2,
    KeyVal,
    RoutePriceVehicleV2,
    TicketVehicleCategory,
    TicketVehicleEngineCategory,
    TicketVehicleMotoCategory,
    TripPriceVehicleAccommodationV2,
} from '@naus-code/naus-client-types';
import {
    VehMotoCategoryItem,
    VehicleSelectCategory,
    VehicleSelectCategoryReq,
    VehicleSelectEngineCategory,
    VehicleSelectEngineCategoryReq,
    VehicleSelectMake,
    VehicleSelectMakeReq,
    VehicleSelectModel,
    VehicleSelectModelReq,
    VehicleSelectMotoCategory,
    VehicleSelectMotoCategoryReq,
    VehicleSelectOptionsReq,
    VehicleSelectSideCar,
    VehicleSelectSize,
    VehicleSelectSizeReq,
    VehicleSelectState,
    VehicleSizeItem,
    VehicleStateValidate,
} from '../types/providers';
import { BasketManagerPetsQuotes } from './bm.5.pet.1.quote';
import { createError } from '../errors';
import { ClientBasketState } from '../types/clientState';
import { cloneDeep, generateRandomString } from '../utils/functions';

export class BasketManagerVehiclesQuotes extends BasketManagerPetsQuotes {
    //
    //---------------VEHICLE_SELECT---------------//

    getVehicleAddState = async (
        vehicleId?: string,
        _options?: {
            category?: TicketVehicleCategory;
        },
    ): Promise<VehicleSelectState> => {
        await this.refreshVehicleModelCache();

        if (!vehicleId) {
            return this.getStartingVehicleState(undefined);
        }
        const checkPointState = this.getStartingVehicleState(undefined);

        checkPointState.vehicleId = vehicleId;

        const vehicleData = this.vehicleGetVehRoutePrice(vehicleId);

        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleData.id);

        checkPointState.category.selected = {
            id: savedVehicle ? vehicleData.id : vehicleData.category,
            label: savedVehicle
                ? this.getSavedVehicleLabel(savedVehicle)
                : this.vehicleGetCategoryLabel(vehicleData.category),
            type: savedVehicle ? 'saved' : 'guest',
            remove: () => checkPointState,
        };

        const validRes = await this.validateVehicleSelectState(checkPointState, vehicleData);
        return validRes.state;
    };

    private getSavedVehicleLabel = (savedVehicle: CustomerClientVehicleV2) => {
        if (savedVehicle.model) {
            return `${savedVehicle.model.label} [${savedVehicle.plateNumber}]`;
        }
        return `${savedVehicle.plateNumber}`;
    };

    setVehicleAddStateCategory = async (vehState: VehicleSelectCategoryReq): Promise<VehicleSelectState> => {
        const { currentState } = vehState;
        //
        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehState.id);
        if (savedVehicle) {
            const vehicleSelected: VehicleSelectCategory['selected'] = {
                id: vehState.id,
                label: this.getSavedVehicleLabel(savedVehicle),
                type: 'saved',
                remove: () => this.getStartingVehicleState(undefined),
            };
            currentState.category.selected = vehicleSelected;
            const validRes = await this.validateVehicleSelectState(currentState);
            return validRes.state;
        }

        const guestVehicleSelected: VehicleSelectCategory['selected'] = {
            id: vehState.id,
            label: this.vehicleGetCategoryLabel(vehState.id as TicketVehicleCategory),
            type: 'guest',
            remove: () => this.getStartingVehicleState(undefined),
        };
        currentState.category.selected = guestVehicleSelected;
        const validRes = await this.validateVehicleSelectState(currentState);
        return validRes.state;
    };

    // CAR
    setVehicleAddStateCarMake = async (vehState: VehicleSelectMakeReq): Promise<VehicleSelectState> => {
        const { currentState } = vehState;

        if (!currentState.make) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }

        //Unknown make
        if (vehState.id === this.OTHER_MAKE) {
            const makeSelected: VehicleSelectMake['selected'] = {
                id: this.OTHER_MAKE,
                label: this.trns('app.passengerTicketSelection.other'),
                remove: () => currentState,
            };
            currentState.make.selected = makeSelected;
            const validRes = await this.validateVehicleSelectState(currentState);
            return validRes.state;
        }

        //Known make
        const makesAvailable = await this.getCarMakeList();
        const chosenMake = makesAvailable.list.find((make) => make.id === vehState.id);
        if (!chosenMake) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }
        const makeSelected: VehicleSelectMake['selected'] = {
            id: chosenMake.id,
            label: chosenMake.label,
            remove: () => currentState,
        };
        currentState.make.selected = makeSelected;
        const validRes = await this.validateVehicleSelectState(currentState);
        return validRes.state;
    };

    setVehicleAddStateCarModel = async (vehState: VehicleSelectModelReq): Promise<VehicleSelectState> => {
        const { currentState } = vehState;

        if (!currentState.model) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }

        //Known model
        const chosenModel = await this.getCarModel(vehState.id);
        if (!chosenModel) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }
        const modelSelected: VehicleSelectModel['selected'] = {
            id: chosenModel.vehicle.id,
            makeId: chosenModel.vehicle.makeId,
            label: chosenModel.vehicle.label,
            remove: () => currentState,
        };
        currentState.model.selected = modelSelected;
        const validRes = await this.validateVehicleSelectState(currentState);
        return validRes.state;
    };

    setVehicleAddStateEngine = async (vehState: VehicleSelectEngineCategoryReq): Promise<VehicleSelectState> => {
        const { currentState } = vehState;
        if (!currentState.engineCategory) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }
        const sizeSelected: VehicleSelectEngineCategory['selected'] = {
            id: vehState.id,
            label: this.vehicleMapEngineLabel({ engineType: vehState.id }),
            remove: () => currentState,
        };
        currentState.engineCategory.selected = sizeSelected;
        const validRes = await this.validateVehicleSelectState(currentState);
        return validRes.state;
    };

    setVehicleAddStateSize = async (vehState: VehicleSelectSizeReq): Promise<VehicleSelectState> => {
        const { currentState } = vehState;

        if (!currentState.size) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }
        const sizeSelected: VehicleSelectSize['selected'] = {
            length: vehState.length,
            height: vehState.height,
            label: this.vehicleMapSizeLabel(vehState),
            remove: () => currentState,
        };
        currentState.size.selected = sizeSelected;
        const validRes = await this.validateVehicleSelectState(currentState);
        return validRes.state;
    };

    //MOTO
    setVehicleAddStateMotoCategory = async (vehState: VehicleSelectMotoCategoryReq): Promise<VehicleSelectState> => {
        const { currentState } = vehState;

        if (!currentState.motoCategory) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }
        const motoCategorySelected: VehicleSelectMotoCategory['selected'] = {
            id: vehState.id,
            label: this.vehicleGetCategoryLabel(vehState.id),
            remove: () => currentState,
        };
        currentState.motoCategory.selected = motoCategorySelected;
        const validRes = await this.validateVehicleSelectState(currentState);
        return validRes.state;
    };

    setVehicleAddStateMotoMake = async (vehState: VehicleSelectMakeReq): Promise<VehicleSelectState> => {
        const { currentState } = vehState;

        if (!currentState.make) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }

        //Unknown make
        if (vehState.id === this.OTHER_MAKE) {
            const makeSelected: VehicleSelectMake['selected'] = {
                id: this.OTHER_MAKE,
                label: this.trns('app.passengerTicketSelection.other'),
                remove: () => currentState,
            };
            currentState.make.selected = makeSelected;
            const validRes = await this.validateVehicleSelectState(currentState);
            return validRes.state;
        }

        //Known make
        const makesAvailable = await this.getMotoMakeList();
        const chosenMake = makesAvailable.list.find((make) => make.id === vehState.id);
        if (!chosenMake) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }
        const makeSelected: VehicleSelectMake['selected'] = {
            id: chosenMake.id,
            label: chosenMake.label,
            remove: () => currentState,
        };
        currentState.make.selected = makeSelected;
        const validRes = await this.validateVehicleSelectState(currentState);
        return validRes.state;
    };

    //ADDONS
    setVehicleEnableStateAddon = async (vehState: VehicleSelectOptionsReq) => {
        const { currentState } = vehState;
        switch (vehState.addon) {
            case 'trailer': {
                currentState.trailer = {
                    length: [],
                    height: [],
                };
                this.appendTrailer(currentState, { ...currentState }, undefined);
                const validRes = await this.validateVehicleSelectState(currentState);
                return validRes.state;
            }
            case 'roof': {
                currentState.roof = {
                    length: [],
                    height: [],
                };
                this.appendRoof(currentState, { ...currentState }, undefined);
                const validRes = await this.validateVehicleSelectState(currentState);
                return validRes.state;
            }
            case 'sideCar': {
                currentState.sideCar = {
                    selected: {
                        remove: () => currentState,
                    },
                };
                this.appendSideCar(currentState, { ...currentState }, undefined);
                const validRes = await this.validateVehicleSelectState(currentState);
                return validRes.state;
            }
            default: {
                const validRes = await this.validateVehicleSelectState(currentState);
                return validRes.state;
            }
        }
    };

    setVehicleAddStateTrailer = async (vehState: VehicleSelectSizeReq) => {
        const { currentState } = vehState;

        if (!currentState.trailer) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }
        const sizeSelected: VehicleSelectSize['selected'] = {
            length: vehState.length,
            height: vehState.height,
            label: this.vehicleMapSizeLabel(vehState),
            remove: () => currentState,
        };
        currentState.trailer.selected = sizeSelected;
        const validRes = await this.validateVehicleSelectState(currentState);
        return validRes.state;
    };

    setVehicleAddStateRoof = async (vehState: VehicleSelectSizeReq) => {
        const { currentState } = vehState;

        if (!currentState.roof) {
            return (await this.validateVehicleSelectState(currentState)).state;
        }
        const sizeSelected: VehicleSelectSize['selected'] = {
            length: vehState.length,
            height: vehState.height,
            label: this.vehicleMapSizeLabel(vehState),
            remove: () => currentState,
        };
        currentState.roof.selected = sizeSelected;
        const validRes = await this.validateVehicleSelectState(currentState);
        return validRes.state;
    };

    //---------------VEHICLE_SELECT_VALIDATE---------------//
    private getStartingVehicleState = (existingVehicle: RoutePriceVehicleV2 | undefined): VehicleSelectState => {
        const bmState = this.getBmState();
        // const validCategories: TicketVehicleCategory[] = ['car', 'moto', 'bike', 'camper', 'bus'];
        const validCategories: TicketVehicleCategory[] = ['car', 'moto', 'camper'];
        const eligibleDriverText = ` (${this.trns('app.basketManager.quote.eligibleDriverRequired', {
            age: this.DRIVER_MIN_AGE,
        })})`;
        return {
            vehicleId: existingVehicle?.id,
            category: {
                selected: undefined,
                guest: validCategories.map((category) => {
                    const hasEligibleDriver = this.getEligibleDriver(category);
                    const validationDisabled =
                        bmState.basketDataProcessed.trips.validation.disabledVehicles?.[category];
                    const eligibleDriverLabel = hasEligibleDriver ? '' : eligibleDriverText;
                    return {
                        id: category,
                        type: 'guest' as const,
                        category,
                        label: this.vehicleGetCategoryLabel(category) + eligibleDriverLabel,
                        disabled: validationDisabled || !hasEligibleDriver,
                        adultRequired: !hasEligibleDriver,
                    };
                }),
                saved: bmState.savedVehicles.map((veh) => {
                    const alreadyChosen = !!this.vehicleGetVehRoutePriceNoThrow(veh.id);
                    const hasEligibleDriver = this.getEligibleDriver(veh.category);
                    const validationDisabled =
                        bmState.basketDataProcessed.trips.validation.disabledVehicles?.[veh.category];
                    const eligibleDriverLabel = hasEligibleDriver ? '' : eligibleDriverText;
                    return {
                        id: veh.id,
                        type: 'saved',
                        //
                        label: this.getSavedVehicleLabel(veh) + eligibleDriverLabel,
                        category: veh.category,
                        //
                        imgUrl: veh.model?.makeImgUrl,
                        //
                        disabled: validationDisabled || !hasEligibleDriver || alreadyChosen,
                        adultRequired: !hasEligibleDriver,
                    };
                }),
            },
        };
    };

    private validateVehicleSelectState = async (
        vehicleState: VehicleSelectState,
        existingVehicle?: RoutePriceVehicleV2,
    ): Promise<VehicleStateValidate> => {
        const vehicleSelected = vehicleState.category.selected;

        // const existingVehicle = existingVehicleProps
        //     || (vehicleState.vehicleId ? this.vehicleGetVehRoutePrice(vehicleState.vehicleId) : undefined)

        if (!vehicleSelected) {
            return { valid: false, state: this.getStartingVehicleState(existingVehicle) };
        }

        const vehicleSelectedId = vehicleSelected.id;
        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleSelectedId);

        const category =
            savedVehicle?.category ||
            existingVehicle?.category ||
            (isVehicleIdCategory(vehicleSelectedId) ? vehicleSelectedId : undefined);

        if (!category) {
            throw createError({ code: 'VEHICLE_CATEGORY_NOT_FOUND' });
        }

        switch (category) {
            case 'car':
                return this.validateVehicleSelectStateCar(vehicleState, existingVehicle);
            //Bus/Camper
            case 'bus':
            case 'camper':
                return this.validateVehicleSelectStateBus(vehicleState, existingVehicle);
            //Bike
            case 'bike':
                return this.validateVehicleSelectStateBike(vehicleState, existingVehicle);
            //Moto
            case 'moto':
            case 'quad':
            case 'trike':
                return this.validateVehicleSelectStateMoto(vehicleState, existingVehicle);
            default:
                return {
                    valid: false,
                    state: this.getStartingVehicleState(existingVehicle),
                };
        }
    };

    private OTHER_MAKE = 'OTHER_MAKE';

    //Car
    private validateVehicleSelectStateCar = async (
        vehicleState: VehicleSelectState,
        existingVehicle: RoutePriceVehicleV2 | undefined,
    ): Promise<VehicleStateValidate> => {
        const vehicleSelected = vehicleState.category.selected;
        const checkPointState = this.getStartingVehicleState(existingVehicle);
        checkPointState.vehicleId = vehicleState.vehicleId;
        checkPointState.category.selected = vehicleSelected;
        if (!vehicleSelected) {
            return { valid: false, state: checkPointState };
        }
        this.appendVehicleRemoveToSelected(checkPointState, 'category');
        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleSelected.id);

        if (!savedVehicle) {
            //---MAKE
            const makes = await this.getCarMakeList();
            if (makes.list.length) {
                checkPointState.make = {
                    list: [
                        {
                            id: this.OTHER_MAKE,
                            label: this.trns('app.passengerTicketSelection.other'),
                        },
                        ...makes.list,
                    ],
                };
                const makeSelected: VehicleSelectMake['selected'] = existingVehicle
                    ? {
                          id: existingVehicle.model?.makeId || this.OTHER_MAKE,
                          label:
                              makes.list.find((make) => make.id === existingVehicle.model?.makeId)?.label ||
                              this.trns('app.passengerTicketSelection.other'),
                          remove: () => checkPointState,
                      }
                    : vehicleState.make?.selected;
                checkPointState.make.selected = makeSelected;
                if (!makeSelected) {
                    return { valid: false, state: checkPointState };
                }
                this.appendVehicleRemoveToSelected(checkPointState, 'make');

                if (makeSelected.id === this.OTHER_MAKE) {
                    //---SIZE
                    checkPointState.size = {
                        length: this.generateSizes({ min: 3, max: 6, incr: 0.5 }),
                        height: this.generateSizes({ min: 1, max: 2.5, incr: 0.5 }),
                    };
                    const sizeSelected: VehicleSelectSize['selected'] = existingVehicle?.size
                        ? {
                              length: existingVehicle.size.length,
                              height: existingVehicle.size.height,
                              label: this.vehicleMapSizeLabel(existingVehicle.size),
                              remove: () => checkPointState,
                          }
                        : vehicleState.size?.selected;
                    checkPointState.size.selected = sizeSelected;
                    if (!sizeSelected) {
                        return { valid: false, state: checkPointState };
                    }
                    this.appendVehicleRemoveToSelected(checkPointState, 'size');
                    //
                } else {
                    //---MODEL
                    const models = await this.getCarModelsList(makeSelected.id);

                    checkPointState.model = {
                        list: models.list,
                    };
                    const modelSelected: VehicleSelectModel['selected'] = existingVehicle?.model
                        ? {
                              id: existingVehicle.model.id,
                              label: models.list.find((item) => item.id === existingVehicle.model?.id)?.label || '',
                              makeId: existingVehicle.model.makeId,
                              remove: () => checkPointState,
                          }
                        : vehicleState.model?.selected;
                    checkPointState.model.selected = modelSelected;
                    if (!modelSelected) {
                        return { valid: false, state: checkPointState };
                    }
                    this.appendVehicleRemoveToSelected(checkPointState, 'model');
                }
            } else {
                //---SIZE
                checkPointState.size = {
                    length: this.generateSizes({ min: 3, max: 6, incr: 0.5 }),
                    height: this.generateSizes({ min: 1.5, max: 3, incr: 0.5 }),
                };
                const sizeSelected: VehicleSelectSize['selected'] = existingVehicle?.size
                    ? {
                          length: existingVehicle.size.length,
                          height: existingVehicle.size.height,
                          label: this.vehicleMapSizeLabel(existingVehicle.size),
                          remove: () => checkPointState,
                      }
                    : vehicleState.size?.selected;
                checkPointState.size.selected = sizeSelected;
                if (!sizeSelected) {
                    return { valid: false, state: checkPointState };
                }
                this.appendVehicleRemoveToSelected(checkPointState, 'size');
            }
        }

        //---ENGINE CATEGORY
        const engineCategoryState = await this.appendEngineCategory(
            vehicleState,
            checkPointState,
            'car',
            existingVehicle,
        );
        if (engineCategoryState) {
            return engineCategoryState;
        }

        //---OPTIONS
        checkPointState.options = {
            trailer: true,
            roof: true,
        };

        checkPointState.readyToComplete = true;

        //---TRAILER
        const trailerState = await this.appendTrailer(vehicleState, checkPointState, existingVehicle);
        if (trailerState) {
            return trailerState;
        }

        //---ROOF
        const roofState = await this.appendRoof(vehicleState, checkPointState, existingVehicle);
        if (roofState) {
            return roofState;
        }

        //
        return { valid: true, state: checkPointState };
    };

    //Bus/Camper
    private validateVehicleSelectStateBus = async (
        vehicleState: VehicleSelectState,
        existingVehicle: RoutePriceVehicleV2 | undefined,
    ): Promise<VehicleStateValidate> => {
        const vehicleSelected = vehicleState.category.selected;
        const checkPointState = this.getStartingVehicleState(existingVehicle);
        checkPointState.vehicleId = vehicleState.vehicleId;
        checkPointState.category.selected = vehicleSelected;
        if (!vehicleSelected) {
            return { valid: false, state: checkPointState };
        }
        this.appendVehicleRemoveToSelected(checkPointState, 'category');

        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleSelected.id);

        if (!savedVehicle) {
            checkPointState.size = {
                length: this.generateSizes({ min: 4.5, max: 10, incr: 0.5 }),
                height: this.generateSizes({ min: 1.5, max: 4.5, incr: 0.5 }),
            };
            const sizeSelected: VehicleSelectSize['selected'] = existingVehicle?.size
                ? {
                      length: existingVehicle.size.length,
                      height: existingVehicle.size.height,
                      label: this.vehicleMapSizeLabel(existingVehicle.size),
                      remove: () => checkPointState,
                  }
                : vehicleState.size?.selected;
            checkPointState.size.selected = sizeSelected;
            if (!sizeSelected) {
                return { valid: false, state: checkPointState };
            }
            this.appendVehicleRemoveToSelected(checkPointState, 'size');
        }

        //---ENGINE CATEGORY
        const engineCategoryState = await this.appendEngineCategory(
            vehicleState,
            checkPointState,
            'car',
            existingVehicle,
        );

        if (engineCategoryState) {
            return engineCategoryState;
        }

        //---OPTIONS
        checkPointState.options = {
            trailer: true,
        };
        checkPointState.readyToComplete = true;

        //---TRAILER
        const trailerState = await this.appendTrailer(vehicleState, checkPointState, existingVehicle);
        if (trailerState) {
            return trailerState;
        }

        //
        return { valid: true, state: checkPointState };
    };

    //Bike
    private validateVehicleSelectStateBike = async (
        vehicleState: VehicleSelectState,
        existingVehicle: RoutePriceVehicleV2 | undefined,
    ): Promise<VehicleStateValidate> => {
        const vehicleSelected = vehicleState.category.selected;
        const checkPointState = this.getStartingVehicleState(existingVehicle);
        checkPointState.vehicleId = vehicleState.vehicleId;
        checkPointState.category.selected = vehicleSelected;
        if (!vehicleSelected) {
            return { valid: false, state: checkPointState };
        }
        this.appendVehicleRemoveToSelected(checkPointState, 'category');

        checkPointState.readyToComplete = true;
        return { valid: true, state: checkPointState };
    };

    //Moto
    private validateVehicleSelectStateMoto = async (
        vehicleState: VehicleSelectState,
        existingVehicle: RoutePriceVehicleV2 | undefined,
    ): Promise<VehicleStateValidate> => {
        const vehicleSelected = vehicleState.category.selected;
        const checkPointState = this.getStartingVehicleState(existingVehicle);
        checkPointState.vehicleId = vehicleState.vehicleId;
        checkPointState.category.selected = vehicleSelected;
        if (!vehicleSelected) {
            return { valid: false, state: checkPointState };
        }
        this.appendVehicleRemoveToSelected(checkPointState, 'category');

        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleSelected.id);

        const category =
            savedVehicle?.category || (isVehicleIdCategory(vehicleSelected.id) ? vehicleSelected.id : undefined);

        if (!category) {
            throw createError({ code: 'VEHICLE_CATEGORY_NOT_FOUND' });
        }
        const motoCategory: TicketVehicleCategory = vehicleState.motoCategory?.selected?.id || category;

        if (category === 'moto' && !savedVehicle && !existingVehicle) {
            //---MOTO CATEGORY
            checkPointState.motoCategory = {
                list: this.getMotoCategories(),
            };
            const motoCategorySelected = vehicleState.motoCategory?.selected;
            checkPointState.motoCategory.selected = motoCategorySelected;
            if (!motoCategorySelected) {
                return { valid: false, state: checkPointState };
            }
            this.appendVehicleRemoveToSelected(checkPointState, 'motoCategory');
        }

        if (motoCategory === 'moto' && !savedVehicle) {
            //---MAKE
            const makes = await this.getMotoMakeList();
            if (makes.list.length) {
                checkPointState.make = {
                    list: [
                        {
                            id: this.OTHER_MAKE,
                            label: this.trns('app.passengerTicketSelection.other'),
                        },
                        ...makes.list,
                    ],
                };
                const makeSelected: VehicleSelectMake['selected'] = existingVehicle
                    ? {
                          id: existingVehicle.model?.makeId || this.OTHER_MAKE,
                          label:
                              makes.list.find((make) => make.id === existingVehicle.model?.makeId)?.label ||
                              this.trns('app.passengerTicketSelection.other'),
                          remove: () => checkPointState,
                      }
                    : vehicleState.make?.selected;
                checkPointState.make.selected = makeSelected;
                if (!makeSelected) {
                    return { valid: false, state: checkPointState };
                }
                this.appendVehicleRemoveToSelected(checkPointState, 'make');

                if (makeSelected.id === this.OTHER_MAKE) {
                    //TODO Moto size relevant?
                    //---SIZE
                    // checkPointState.size = {
                    //     length: this.generateSizes({ min: 3, max: 6, incr: 0.5 }),
                    //     height: this.generateSizes({ min: 1.5, max: 3, incr: 0.5 }),
                    // };
                    // const sizeSelected: VehicleSelectSize['selected'] =
                    //     existingVehicle?.size
                    //         ? {
                    //               length: existingVehicle.size.length,
                    //               height: existingVehicle.size.height,
                    //               remove: () => checkPointState,
                    //           }
                    //         : vehicleState.size?.selected;
                    // checkPointState.size.selected = sizeSelected;
                    // if (!sizeSelected) {
                    //     return { valid: false, state: checkPointState };
                    // }
                    // this.appendVehicleRemoveToSelected(checkPointState, 'size');
                    //
                } else {
                    //---MODEL
                    const models = await this.getMotoModelsList(makeSelected.id);

                    checkPointState.model = {
                        list: models.list,
                    };
                    const modelSelected: VehicleSelectModel['selected'] = existingVehicle?.model
                        ? {
                              id: existingVehicle.model.id,
                              label: models.list.find((item) => item.id === existingVehicle.model?.id)?.label || '',
                              makeId: existingVehicle.model.makeId,
                              remove: () => checkPointState,
                          }
                        : vehicleState.model?.selected;
                    checkPointState.model.selected = modelSelected;
                    if (!modelSelected) {
                        return { valid: false, state: checkPointState };
                    }
                    this.appendVehicleRemoveToSelected(checkPointState, 'model');
                }
            } else {
                // ---SIZE
                checkPointState.size = {
                    length: this.generateSizes({ min: 1, max: 2, incr: 0.5 }),
                    height: this.generateSizes({ min: 1, max: 2, incr: 0.5 }),
                };
                const sizeSelected: VehicleSelectSize['selected'] = existingVehicle?.size
                    ? {
                          length: existingVehicle.size.length,
                          height: existingVehicle.size.height,
                          label: this.vehicleMapSizeLabel(existingVehicle.size),
                          remove: () => checkPointState,
                      }
                    : vehicleState.size?.selected;
                checkPointState.size.selected = sizeSelected;
                if (!sizeSelected) {
                    return { valid: false, state: checkPointState };
                }
                this.appendVehicleRemoveToSelected(checkPointState, 'size');
            }
        }

        //---ENGINE CATEGORY
        const engineCategoryState = await this.appendEngineCategory(
            vehicleState,
            checkPointState,
            'moto',
            existingVehicle,
        );

        if (engineCategoryState) {
            return engineCategoryState;
        }

        if (motoCategory === 'moto') {
            //---OPTIONS
            checkPointState.options = {
                sideCar: true,
            };
            checkPointState.readyToComplete = true;
            //---SIDE CAR
            const sideCarState = await this.appendSideCar(vehicleState, checkPointState, existingVehicle);
            if (sideCarState) {
                return sideCarState;
            }
        }

        checkPointState.readyToComplete = true;
        return { valid: true, state: checkPointState };
    };

    private getMotoCategories = (): VehMotoCategoryItem[] => {
        const categories: TicketVehicleMotoCategory[] = ['moto', 'quad', 'trike'];
        return categories.map((id) => ({
            id,
            label: this.vehicleGetCategoryLabel(id),
        }));
    };

    //---------------SIZE---------------//
    // private appendSize = (
    //     vehicleState: VehicleSelectState,
    //     checkPointState: VehicleSelectState,
    // ) => {
    //     checkPointState.size = {
    //         length: this.generateSizes({ min: 3, max: 6, incr: 0.5 }),
    //         height: this.generateSizes({ min: 1.5, max: 3, incr: 0.5 }),
    //     };
    //     const sizeSelected = vehicleState.size?.selected;
    //     checkPointState.size.selected = sizeSelected;
    //     if (!sizeSelected) {
    //         return { valid: false, state: checkPointState };
    //     }
    //     this.appendVehicleRemoveToSelected(checkPointState, 'size');
    // };

    //---------------ENGINE_CATEGORY---------------//
    private appendEngineCategory = async (
        vehicleState: VehicleSelectState,
        checkPointState: VehicleSelectState,
        type: 'car' | 'moto',
        existingVehicle: RoutePriceVehicleV2 | undefined,
    ): Promise<VehicleStateValidate | undefined> => {
        const bmState = this.getBmState();
        const requiresEngineCategory = bmState.basketDataProcessed.trips.validation.vehicleEngineCategoryRequired;
        if (!requiresEngineCategory) {
            //Category not required
            return;
        }

        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleState.category.selected?.id);

        if (savedVehicle?.engineCategory) {
            //Saved vehicle has category
            return;
        }

        const modelId = savedVehicle?.model?.id || existingVehicle?.model?.id || vehicleState.model?.selected?.id;

        if (modelId) {
            const model = type === 'car' ? await this.getCarModel(modelId) : await this.getMotoModel(modelId);
            if (model) {
                if (!model.vehicle.engineCategories?.length) {
                    //Model has one engine categories, stored on server
                    return;
                }

                //Model has engine categories
                checkPointState.engineCategory = {
                    list: model.vehicle.engineCategories.map((item) => ({
                        type: item.type,
                        label: this.vehicleMapEngineLabel({ engineType: item.type }),
                    })),
                };
                const engineCategorySelected: VehicleSelectEngineCategory['selected'] = existingVehicle?.engineCategory
                    ? {
                          id: existingVehicle.engineCategory,
                          label: this.getEngineCategoryLabel(existingVehicle.engineCategory),
                          remove: () => checkPointState,
                      }
                    : vehicleState.engineCategory?.selected;
                checkPointState.engineCategory.selected = engineCategorySelected;
                if (!engineCategorySelected) {
                    return { valid: false, state: checkPointState };
                }
                this.appendVehicleRemoveToSelected(checkPointState, 'engineCategory');
                //Continue
                return;
            }
        }

        //Standard engineCategories
        const categories: TicketVehicleEngineCategory[] = ['diesel', 'petrol', 'hybrid', 'plug-in', 'gas'];
        checkPointState.engineCategory = {
            list: categories.map((type) => ({
                type,
                label: this.getEngineCategoryLabel(type),
            })),
        };
        const engineCategorySelected: VehicleSelectEngineCategory['selected'] = existingVehicle?.engineCategory
            ? {
                  id: existingVehicle.engineCategory,
                  label: this.getEngineCategoryLabel(existingVehicle.engineCategory),
                  remove: () => checkPointState,
              }
            : vehicleState.engineCategory?.selected;

        checkPointState.engineCategory.selected = engineCategorySelected;
        if (!engineCategorySelected) {
            return { valid: false, state: checkPointState };
        }
        this.appendVehicleRemoveToSelected(checkPointState, 'engineCategory');
        //Continue
    };

    private getEngineCategoryLabel = (type: TicketVehicleEngineCategory) => {
        //TODO Translate Engine category label
        return type;
    };

    //---------------VEH_OPTIONS---------------//
    private appendTrailer = (
        vehicleState: VehicleSelectState,
        checkPointState: VehicleSelectState,
        existingVehicle: RoutePriceVehicleV2 | undefined,
    ) => {
        if (vehicleState.roof) {
            checkPointState.roof = vehicleState.roof;
        }
        if (!vehicleState.trailer && !existingVehicle?.trailerData) {
            return;
        }
        checkPointState.trailer = {
            length: [
                ...this.generateSizes({ min: 0, max: 2, incr: 2 }),
                ...this.generateSizes({ min: 2, max: 8, incr: 1 }),
            ],
            height: this.generateSizes({ min: 0.5, max: 3.5, incr: 1 }),
        };
        const trailerSelected: VehicleSelectSize['selected'] = existingVehicle?.trailerData
            ? {
                  length: existingVehicle.trailerData.length,
                  height: existingVehicle.trailerData.height,
                  label: this.vehicleMapSizeLabel(existingVehicle.trailerData),
                  remove: () => checkPointState,
              }
            : vehicleState.trailer?.selected;
        checkPointState.trailer.selected = trailerSelected;
        if (!trailerSelected) {
            checkPointState.readyToComplete = false;
            checkPointState.options = undefined;
            return { valid: false, state: checkPointState };
        }
        checkPointState.options = {
            roof: !checkPointState.roof || undefined,
        };
        this.appendVehicleRemoveToSelected(checkPointState, 'trailer');
        this.appendVehicleRemoveToSelected({ ...checkPointState, options: { roof: true } }, 'roof');
        checkPointState.options = {
            roof: true,
        };
        //Continue
    };

    private appendRoof = (
        vehicleState: VehicleSelectState,
        checkPointState: VehicleSelectState,
        existingVehicle: RoutePriceVehicleV2 | undefined,
    ) => {
        if (vehicleState.trailer) {
            checkPointState.trailer = vehicleState.trailer;
        }
        if (!vehicleState.roof && !existingVehicle?.roofData) {
            return;
        }
        checkPointState.roof = {
            length: this.generateSizes({ min: 0, max: 4, incr: 1 }),
            height: this.generateSizes({ min: 0, max: 1.5, incr: 0.5 }),
        };

        const roofSelected: VehicleSelectSize['selected'] = existingVehicle?.roofData
            ? {
                  length: existingVehicle.roofData.length,
                  height: existingVehicle.roofData.height,
                  label: this.vehicleMapSizeLabel(existingVehicle.roofData),
                  remove: () => checkPointState,
              }
            : vehicleState.roof?.selected;
        checkPointState.roof.selected = roofSelected;
        if (!roofSelected) {
            checkPointState.readyToComplete = false;
            checkPointState.options = undefined;
            return { valid: false, state: checkPointState };
        }
        checkPointState.options = {
            trailer: !checkPointState.trailer || undefined,
        };
        this.appendVehicleRemoveToSelected(checkPointState, 'roof');
        this.appendVehicleRemoveToSelected({ ...checkPointState, options: { trailer: true } }, 'trailer');

        if (vehicleState.trailer) {
            checkPointState.options = undefined;
        }
        //Continue
    };

    private appendSideCar = (
        vehicleState: VehicleSelectState,
        checkPointState: VehicleSelectState,
        existingVehicle: RoutePriceVehicleV2 | undefined,
    ) => {
        if (!vehicleState.sideCar && !existingVehicle?.sideCarData) {
            return;
        }

        checkPointState.sideCar = {};

        const sideCarSelected: VehicleSelectSideCar['selected'] = existingVehicle?.sideCarData
            ? {
                  remove: () => checkPointState,
              }
            : vehicleState.sideCar?.selected;
        checkPointState.sideCar.selected = sideCarSelected;
        if (!sideCarSelected) {
            checkPointState.readyToComplete = false;
            checkPointState.options = undefined;
            return { valid: false, state: checkPointState };
        }
        this.appendVehicleRemoveToSelected(checkPointState, 'sideCar');
        checkPointState.options = undefined;
        //Continue
    };

    //---------------REMOVE_FUNC---------------//
    private appendVehicleRemoveToSelected = (
        vehicleState: VehicleSelectState,
        key: keyof Omit<VehicleSelectState, 'readyToComplete' | 'vehicleId' | 'options'>,
    ) => {
        if (!vehicleState[key]?.selected) {
            return;
        }
        let removeState = { ...vehicleState };
        // const removeState = cloneDeep(vehicleState);
        vehicleState[key]!.selected!.remove = (currentState?: VehicleSelectState) => {
            switch (key) {
                case 'category':
                    removeState[key]!.selected = undefined;
                    return removeState;
                case 'trailer':
                case 'roof':
                case 'sideCar':
                    if (currentState) {
                        removeState = cloneDeep(currentState);
                    }
                    if (!removeState.options) {
                        removeState.options = {};
                    }
                    removeState.options![key] = true;
                    removeState[key] = undefined;
                    return removeState;
                default:
                    removeState[key]!.selected = undefined;
                    return removeState;
            }
        };
    };

    //---------------ADD---------------//
    addVehicle = async (vehState: VehicleSelectState, options?: { state?: ClientBasketState }) => {
        const bmState = this.getBmState();
        const { basketDataProcessed, routePrice } = bmState;
        if (!basketDataProcessed.vehicles.allowed) {
            this._log('addVehicle', 'Vehicles not allowed');
            return;
        }
        if (basketDataProcessed.vehicles.soldOut) {
            this._log('addVehicle', 'Vehicles sold out');
            return;
        }
        const vehicleIdToReplace = vehState.vehicleId;
        const index = vehicleIdToReplace
            ? routePrice.vehicles.find((vehicle) => vehicle.id === vehicleIdToReplace)?.index
            : routePrice.vehicles.length;

        if (index === undefined) {
            throw createError({ code: 'VEHICLE_NOT_FOUND_FOR_ADD' });
        }

        const { valid } = await this.validateVehicleSelectState(vehState);
        if (!valid) {
            return;
        }

        const vehicleSelected = vehState.category.selected;
        if (!vehicleSelected) {
            throw createError({ code: 'VEHICLE_DATA_INCOMPLETE' });
        }

        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleSelected.id);

        const category =
            savedVehicle?.category ||
            vehState.motoCategory?.selected?.id ||
            (isVehicleIdCategory(vehicleSelected.id) ? vehicleSelected.id : undefined);

        if (!category) {
            throw createError({ code: 'VEHICLE_CATEGORY_NOT_FOUND' });
        }

        const trailerSelected = vehState.trailer?.selected;
        const roofSelected = vehState.roof?.selected;
        const sideCarSelected = vehState.sideCar?.selected;

        const modelData = vehState.model?.selected;
        const model = modelData
            ? {
                  id: modelData.id,
                  makeId: modelData.makeId,
              }
            : undefined;

        const modelFromData = model?.id ? await this.getCarModel(model.id) : undefined;

        const vehicleData: RoutePriceVehicleV2 = {
            id: savedVehicle?.id || generateRandomString(),
            index,
            category,
            // nickName?: string;
            model: savedVehicle?.model || modelFromData?.vehicle,
            plateNumber: savedVehicle?.plateNumber || '',
            size: savedVehicle?.size || vehState.size?.selected,
            engineCategory: savedVehicle?.engineCategory || vehState.engineCategory?.selected?.id,
            // modelData?: RoutePriceVehicleModel;
            // otherData?: RoutePriceVehicleOther;
            trailerData: trailerSelected
                ? {
                      id: generateRandomString(),
                      length: trailerSelected.length,
                      height: trailerSelected.height,
                  }
                : undefined,
            roofData: roofSelected
                ? {
                      id: generateRandomString(),
                      length: roofSelected.length,
                      height: roofSelected.height,
                  }
                : undefined,
            sideCarData: sideCarSelected
                ? {
                      id: generateRandomString(),
                      enabled: true,
                  }
                : undefined,
            // discountCodes?: {
            //     id: string;
            //     name: string;
            //     number: string;
            // }[];
            // discountCode?: KeyVal<KeyVal<{
            //     name: string;
            //     number: string;
            // }>>;
        };

        const vehicleReqPerTrip: KeyVal<TripPriceVehicleAccommodationV2> = {};

        const driver = this.getEligibleDriver(category);

        if (!driver) {
            throw createError({ code: 'NO_ELIGIBLE_DRIVER' });
        }

        for (let tripIndex = 0; tripIndex < bmState.selectedTrips.length; tripIndex++) {
            const trip = bmState.selectedTrips[tripIndex];
            const dic = this.getTripDictionary(trip.key);
            const categoryDic = dic.vehicleCatAccDic[category];
            const existingReq = bmState.routePrice.trips[tripIndex].vehicleAccReq[vehicleData.index];
            const vehicleReq: TripPriceVehicleAccommodationV2 = {
                accCode: categoryDic.accommodations[0].code,
                vehicleData: {
                    vehicleId: vehicleData.id,
                    category: vehicleData.category,
                },
                passengerId: existingReq?.passengerId || driver.id,
            };
            vehicleReqPerTrip[trip.key] = vehicleReq;
            bmState.routePrice.trips[tripIndex].vehicleAccReq[vehicleData.index] = vehicleReq;
        }

        if (!vehicleIdToReplace && bmState.unselectedVehicles.length) {
            bmState.unselectedVehicles.splice(0, 1);
        }

        this.dispatchBasketWrapper(options?.state, (state) => {
            this.dispatchVehicle(vehicleData, {
                state,
                vehicleReqPerTrip,
                selectiveUpdate: {
                    quoteOptions: true,
                    pricing: true,
                },
            });
        });
        this.dispatchManager();

        if (bmState.basketDataProcessed.flowConfig.skipTripPricingDetails) {
            this.dispatchPrices();
        }
    };

    private getEligibleDriver = (vehicleCategory: TicketVehicleCategory) => {
        const { routePrice } = this.getBmState();
        for (const passenger of routePrice.passengers) {
            const isEligible = this.vehicleIsDriverEligible(passenger, vehicleCategory);
            if (isEligible) {
                return passenger;
            }
        }
    };

    removeVehicle = () => {
        const bmState = this.getBmState();

        if (bmState.unselectedVehicles.length) {
            if (bmState.basketDataProcessed.vehicles.required && bmState.unselectedVehicles.length < 2) {
                return;
            }
            bmState.unselectedVehicles.splice(0, 1);
            this.dispatchBasket((state) => {
                state.quoteOptions.vehicles.unselectedList = [...bmState.unselectedVehicles];
            });
            this.dispatchManager();
            return;
        }

        if (!bmState.routePrice.passengers.length) {
            return;
        }

        const vehicleIndex = bmState.routePrice.vehicles.length - 1;
        bmState.routePrice.vehicles.splice(vehicleIndex, 1);

        for (let tripIndex = 0; tripIndex < bmState.selectedTrips.length; tripIndex++) {
            bmState.routePrice.trips[tripIndex].vehicleAccReq.splice(vehicleIndex, 1);
        }

        this.dispatchBasket((state) => {
            state.quoteOptions.vehicles.list.splice(vehicleIndex, 1);
            for (let tripIndex = 0; tripIndex < bmState.routePrice.trips.length; tripIndex++) {
                state.pricing.trips[tripIndex].vehicles.splice(vehicleIndex, 1);
                state.details.vehicles.splice(vehicleIndex, 1);
            }
            state.quoteOptions.query = this.getQueryBreakdownFromRoutePrice(bmState.routePrice);
            this.resetPrices({ state });
        });
        this.dispatchManager();

        if (bmState.basketDataProcessed.flowConfig.skipTripPricingDetails) {
            this.dispatchPrices();
        }
    };

    private generateSizes = (options: { min: number; max: number; incr: number }) => {
        let { min, max, incr } = options;
        if (max < min) {
            return [];
        }
        const sizes: VehicleSizeItem[] = [];
        do {
            const step = min + incr;
            sizes.push({
                size: step * 100,
                label: `${min}m - ${step}m`,
            });
            min = step;
        } while (min < max);
        return sizes;
    };
}

const categoryMap: { [key in TicketVehicleCategory]: true } = {
    car: true,
    moto: true,
    trike: true,
    quad: true,
    bike: true,
    camper: true,
    trailer: true,
    bus: true,
};

function isVehicleIdCategory(id: string): id is TicketVehicleCategory {
    return categoryMap[id as TicketVehicleCategory];
}
