import { FormHandler } from '@droplet-tech-code/core-elements/module/ui/Inputs/Form';
import {
  PurchaseClientWithMetaWithTickets,
  PurchaseUpdate,
  TicketClient,
  TicketUpdate,
} from '@naus-code/naus-admin-types';
import {
  BookingRetrievePurchase,
  BookingRetrievePurchaseFerry,
  BookingRetrieveTicket,
  BookingRetrieveTicketPassenger,
  BookingRetrieveTicketPet,
  BookingRetrieveTicketVehicle,
} from '@naus-code/naus-server-common-types';
import { PurchaseFormData } from '../Modify/Manual/utils/Modify.ManualTrip.purchase.utils';
import { onSubmitValue } from '~/components/SupportRequest/utils/utils';
import {
  BaseTicketFormData,
  getBaseTicketInfo,
  PassengerTicketFormData,
  PetTicketFormData,
  VehicleTicketFormData,
} from '../Modify/Manual/utils/Modify.ManualTrip.ticket.utils';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import { ReplaceFirebaseTimestamp } from '@naus-code/naus-server-utils-common-types';
import {
  TicketPassengerWithHistory,
  TicketPetWithHistory,
  TicketVehicleWithHistory,
} from '@naus-code/naus-client-types';
import { useGetAuthUser } from '~/screens/Auth/Auth.Context';
import { DATE_SERVER_FORMAT } from '~/utils/date.utils';

export function createAutoImportPurchases(
  modificationForms: {
    [key: string]: FormHandler[];
  },
  purchase: BookingRetrievePurchase,
):
  | {
      autoUpdatePurchase: PurchaseUpdate & { vesselId: string };
    }
  | undefined {
  const purchaseForms = modificationForms['0'];
  const purchaseToUpdate = getBasePurchaseToUpdate(purchase, purchaseForms);
  if (!purchaseToUpdate) {
    return;
  }
  const purchaseTickets = purchase.tickets.map((ticket, index) => {
    return {
      _id: index + '',
      ...ticket,
    } as BookingRetrieveTicket & { _id: string };
  });
  const updatedTickets = getTicketsToUpdate(purchaseTickets, '0', modificationForms);
  if (!updatedTickets) {
    return;
  }

  purchaseToUpdate['tickets'] = updatedTickets;

  return {
    autoUpdatePurchase: purchaseToUpdate,
  };
}

function getBasePurchaseToUpdate(
  purchase: BookingRetrievePurchase,
  purchaseForms: FormHandler[],
): PurchaseUpdateImportFerry | undefined {
  let validFlag = true;
  let purchaseFormValues = {} as PurchaseFormData;
  for (const purchaseForm of purchaseForms) {
    const { value, valid } = purchaseForm.getValue<PurchaseFormData>();
    if (valid) {
      purchaseFormValues = { ...purchaseFormValues, ...value };
    } else {
      validFlag = false;
    }
  }
  if (validFlag) {
    const basePurchase = {
      ...purchase,
      tickets: [] as TicketUpdate[],
      origin: purchaseFormValues.origin,
      destination: purchaseFormValues.destination,
      vesselId: purchaseFormValues.vesselId,
      valueCcy: onSubmitValue(purchaseFormValues.valueCcy),
      valueCompanyCcy: onSubmitValue(purchaseFormValues.valueCompanyCcy),
      departureDateTime: purchaseFormValues.departureDateTime,
      arrivalDateTime: purchaseFormValues.arrivalDateTime,
      providerReservationCode: purchaseFormValues.providerReservationCode,
      companyReservationCode: purchaseFormValues.companyReservationCode,
      companyAccessCode: purchaseFormValues.companyAccessCode,
    };
    if (purchase.purchaseProductType === 'FERRY') {
      const ferryPurchase = purchase as PurchaseUpdate & {
        providerVesselName: string;
      };
      return {
        ...basePurchase,

        providerVesselName: ferryPurchase.providerVesselName,
      } as PurchaseUpdateImportFerry;
    }
  }
}

function getTicketsToUpdate(
  purchaseTickets: (BookingRetrieveTicket & { _id: string })[],
  purchaseId: string,
  modificationForms: {
    [key: string]: FormHandler[];
  },
) {
  const ticketsToUpdate: TicketUpdate[] = [];
  for (const ticket of purchaseTickets) {
    const ticketForms = modificationForms[purchaseId + ticket._id];
    let ticketToUpdate: TicketUpdate | undefined;
    switch (ticket.ticketType) {
      case 'Passenger':
        ticketToUpdate = getPassengerTicketsToImport(ticket, ticketForms);
        break;
      case 'Vehicle':
        ticketToUpdate = getVehicleTicketsToImport(ticket, ticketForms);
        break;
      case 'Pet':
        ticketToUpdate = getPetTicketsToImport(ticket, ticketForms);
        break;
      default:
        return undefined;
    }
    if (!ticketToUpdate) {
      return undefined;
    }

    ticketsToUpdate.push(ticketToUpdate);
  }
  return ticketsToUpdate;
}

function getPassengerTicketsToImport(
  ticket: BookingRetrieveTicket & { _id: string },
  ticketForms: FormHandler[],
) {
  let validFlag = true;
  let ticketFormValues = {} as PassengerTicketFormData;
  for (const ticketForm of ticketForms) {
    const { value, valid } = ticketForm.getValue<PassengerTicketFormData>();
    if (valid) {
      ticketFormValues = { ...ticketFormValues, ...value };
    } else {
      validFlag = false;
    }
  }

  const baseTicketInfo: BaseTicketFormData = getBaseTicketInfo(ticketFormValues);
  if (validFlag) {
    return {
      ...ticket,
      ...baseTicketInfo,
      ticketType: 'Passenger',
      companyTicketNumber: ticketFormValues.companyTicketNumber,
      p_firstName: ticketFormValues.p_firstName.toUpperCase(),
      p_lastName: ticketFormValues.p_lastName.toUpperCase(),
      p_sex: ticketFormValues.p_sex.toUpperCase(),
      seatNumber: ticketFormValues.seatNumber,
      p_nationality: ticketFormValues.p_nationality,
      residentId: ticketFormValues.residentId,
      p_birthPlace: ticketFormValues.p_birthPlace,
      p_birthDate: ticketFormValues.p_birthDate,
      p_documentType: ticketFormValues.p_documentType,
      p_documentNumber: ticketFormValues.p_documentNumber,
      p_documentExpirationDate: ticketFormValues.p_documentExpirationDate,
    } as TicketUpdate;
  }
}

function getVehicleTicketsToImport(
  ticket: BookingRetrieveTicket & { _id: string },
  ticketForms: FormHandler[],
) {
  let validFlag = true;
  let ticketFormValues = {} as VehicleTicketFormData;
  for (const ticketForm of ticketForms) {
    const { value, valid } = ticketForm.getValue<VehicleTicketFormData>();
    if (valid) {
      ticketFormValues = { ...ticketFormValues, ...value };
    } else {
      validFlag = false;
    }
  }
  const baseTicketInfo: BaseTicketFormData = getBaseTicketInfo(ticketFormValues);
  if (validFlag) {
    return {
      ...ticket,
      ...baseTicketInfo,
      ticketType: 'Vehicle',
      parentTicketNumber: ticketFormValues.parentTicketNumber,
      companyTicketNumber: ticketFormValues.companyTicketNumber,
      category: [ticketFormValues.category],
      p_plate: ticketFormValues.p_plate,
      loyaltyNumber: ticketFormValues.loyaltyNumber,
      p_nationality: ticketFormValues.p_nationality,
      length: ticketFormValues.length,
      height: ticketFormValues.height,
    } as TicketUpdate;
  }
}

function getPetTicketsToImport(
  ticket: BookingRetrieveTicket & { _id: string },
  ticketForms: FormHandler[],
) {
  let validFlag = true;
  let ticketFormValues = {} as PetTicketFormData;
  for (const ticketForm of ticketForms) {
    const { value, valid } = ticketForm.getValue<PetTicketFormData>();
    if (valid) {
      ticketFormValues = { ...ticketFormValues, ...value };
    } else {
      validFlag = false;
    }
  }
  const baseTicketInfo: BaseTicketFormData = getBaseTicketInfo(ticketFormValues);
  if (validFlag) {
    return {
      ...ticket,
      ...baseTicketInfo,
      ticketType: 'Pet',
      companyTicketNumber: ticketFormValues.companyTicketNumber,
      parentTicketNumber: ticketFormValues.parentTicketNumber,
      parentTicketId: ticketFormValues.parentTicketId,
      name: ticketFormValues.name.toUpperCase(),
    } as TicketUpdate;
  }
}

//Mapping retrieve to client

export function mapTicketToClient(
  ticket: BookingRetrieveTicket,
  purchase: BookingRetrievePurchase,
  index: string,
): TicketClient | undefined {
  const { currentUser } = useGetAuthUser();
  const ticketBase = {
    ...ticket,
    _id: index,
    _purchaseId: '0',
    _bookingId: '',
    agentId: currentUser?.uid,
    history: [] as any,
    companyCcy: purchase.companyCcy,
    ccy: purchase.ccy,
    dateBookingCreated: dayjs().toString(),
    dateDocumentCreated: dayjs().toString(),
    dateLastUpdatedFromProvider: dayjs().toString(),
  };
  if (isTicketPassenger(ticket)) {
    return {
      ...ticketBase,
    } as ReplaceFirebaseTimestamp<TicketPassengerWithHistory>;
  }
  if (isTicketVehicle(ticket)) {
    return {
      ...ticketBase,
      category: ticket.category[0],
    } as ReplaceFirebaseTimestamp<TicketVehicleWithHistory>;
  }
  if (isTicketPet(ticket)) {
    return {
      ...ticketBase,
    } as ReplaceFirebaseTimestamp<TicketPetWithHistory>;
  }
}

export function mapPurchaseRetrieveToClient(
  purchase: BookingRetrievePurchase,
  mappedTickets: TicketClient[],
): PurchaseClientWithMetaWithTickets {
  const ticketIds = mappedTickets.map((ticket) => ticket?._id);

  const purchaseClient: PurchaseClientWithMetaWithTickets = {
    ...purchase,
    _bookingId: '',
    bookingId: '',
    _customerId: '',
    bookingRef: '',
    _id: '0',
    lang: 'en',
    valueCompanyCcy: purchase.value,
    companyCcy: purchase.companyCcy,
    value: purchase.valueCcy,
    ccy: purchase.ccy,
    valueOriginalCcy: purchase.valueCcy,
    valueOriginalCompanyCcy: purchase.value,
    valueCompanyCommission: 0,
    dateBookingCreated: '',
    dateDocumentCreated: '',
    dateLastUpdatedFromProvider: '',
    lastAutoUpdateFromProvider:
      {} as PurchaseClientWithMetaWithTickets['lastAutoUpdateFromProvider'],
    requiresAutoUpdate: false,
    agentId: '',
    supportCount: 0,
    subDistributionProvider: '',
    arrivalDateTime: purchase.arrivalDateTime || '',
    departureTime: dayjs(purchase.departureDateTime).format('HH:mm'),
    operatorImgUrl: '',
    arrivalTime: dayjs(purchase.arrivalDateTime).format('HH:mm'),
    duration: '',
    departureDate: dayjs(purchase.departureDateTime).format(DATE_SERVER_FORMAT),
    departureTimestamp: purchase.departureDateTime,
    endTimestamp: '',
    originDisplay: purchase.origin,
    originTimezone: '',
    destinationDisplay: purchase.destination,
    destinationTimezone: '',
    ticketCount: purchase.tickets.length,
    dateCreated: '',
    companyId: purchase.companyId,
    operator: '',
    vesselName: '',
    vesselId: purchase.vesselId || '',
    historyCount: 0,
    ccySymbol: '',
    p_email: '',
    rate: Math.round((purchase.value * 100) / purchase.valueCcy) / 100,
    valueMarkupCommission: 0,
    startTimestamp_original: purchase.startTimestamp,
    sortTimestamp: purchase.startTimestamp,
    _supportRequestIds: [],
    tickets: mappedTickets,
    _ticketIds: ticketIds,
    history: [] as any,
  };
  return purchaseClient;
}

export function isTicketPassenger(
  ticket: BookingRetrieveTicket,
): ticket is BookingRetrieveTicketPassenger {
  return ticket.ticketType === 'Passenger';
}

export function isTicketVehicle(
  ticket: BookingRetrieveTicket,
): ticket is BookingRetrieveTicketVehicle {
  return ticket.ticketType === 'Vehicle';
}

export function isTicketPet(
  ticket: BookingRetrieveTicket,
): ticket is BookingRetrieveTicketPet {
  return ticket.ticketType === 'Pet';
}

export interface PurchaseUpdateImportFerry extends BookingRetrievePurchaseFerry {
  vesselId: string;
}
