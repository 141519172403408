import { SelectTicketPassengerItem } from '@naus-code/naus-basket-manager';

import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';

import { BasketManagerAppState, useBasketManagerStore } from '../BasketManager.Provider';
import { PassengerProps } from './passenger.types';

/**
 *
 *  PRICING SECTION - Use these hooks for the components where the customer is "selecting their tickets"
 *
 */

const selectPricingTrips = (state: BasketManagerAppState) => state.basket.pricing.trips;
const selectPricingTripsPassengerIdAndIndexes = createSelector(
  selectPricingTrips,
  // Extract the second argument to pass it on
  (_: BasketManagerAppState, tripIndex: number) => tripIndex,
  (trips, tripIndex) =>
    trips[tripIndex].passengers.map((p) => ({
      passengerIndex: p.index,
      passengerId: p.passengerId,
    })),
);

const selectPassengerListItem = (
  s: BasketManagerAppState,
  tripIndex: number,
  passengerIndex: number,
) => s.basket.pricing.trips[tripIndex].passengers[passengerIndex];

const constructPassengerItemFactory = <T extends keyof SelectTicketPassengerItem>(
  props: PassengerProps,
  key: T,
  shallow?: boolean,
) => {
  const useBmStore = useBasketManagerStore();
  return useBmStore(
    (state) => selectPassengerListItem(state, props.tripIndex, props.passengerIndex)[key],
    shallow ? shallowEqual : undefined,
  );
};

export const usePassengerPricing = {
  passengerListByIdAndIndex({ tripIndex }: Pick<PassengerProps, 'tripIndex'>) {
    const useBmStore = useBasketManagerStore();
    return useBmStore(
      (state) => {
        return selectPricingTripsPassengerIdAndIndexes(state, tripIndex);
      },
      (pList1, pList2) => {
        return (
          pList1.map((obj) => obj.passengerId + obj.passengerIndex).join('') ===
          pList2.map((obj) => obj.passengerId + obj.passengerIndex).join('')
        );
      },
    );
  },
  passengerItem(props: PassengerProps) {
    const useBmStore = useBasketManagerStore();
    return useBmStore((state) => {
      const passenger = selectPassengerListItem(
        state,
        props.tripIndex,
        props.passengerIndex,
      );
      return {
        passengerId: passenger.passengerId,
        label: passenger.label,
        description: passenger.description,
      };
    }, shallowEqual);
  },

  extraListByIndexes({ tripIndex }: Pick<PassengerProps, 'tripIndex'>) {
    const useBmStore = useBasketManagerStore();
    return useBmStore((state) =>
      selectPricingTripsPassengerIdAndIndexes(state, tripIndex),
    );
  },

  availableDiscounts(props: PassengerProps) {
    return constructPassengerItemFactory(props, 'availableDiscounts');
  },
  availableExtras(props: PassengerProps) {
    return constructPassengerItemFactory(props, 'availableExtras');
  },
  availableTickets(props: PassengerProps) {
    return constructPassengerItemFactory(props, 'availableTickets');
  },
  loyalty(props: PassengerProps) {
    return constructPassengerItemFactory(props, 'loyalty');
  },
  selectedTicket(props: PassengerProps) {
    return constructPassengerItemFactory(props, 'selectedTicket', true);
  },
  selectedExtras(props: PassengerProps) {
    return constructPassengerItemFactory(props, 'selectedExtras');
  },
  selectedDiscount(props: PassengerProps) {
    return constructPassengerItemFactory(props, 'selectedDiscount', true);
  },
  hasExtraData(props: PassengerProps) {
    return constructPassengerItemFactory(props, 'hasExtraData');
  },
  requiresExtraData(props: PassengerProps) {
    return constructPassengerItemFactory(props, 'requiresExtraData');
  },
  totalValue(props: PassengerProps) {
    const rawValue = constructPassengerItemFactory(props, 'value');
    return {
      rawValue,
    };
  },
};
