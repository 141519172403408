import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isNativeMobile } from '@droplet-tech-code/core-elements/module/utils/utils.helper';

import { PageHeader } from '~/components/Page/Page.Header';

import { OperatorImages } from './CompanyConfig.ListItem';
import { companyConfigApi } from './CompanyConfigs.api';
import { FerryCompanyCmsExtended } from '@naus-code/naus-config-types';

export const CompanyConfigItemHeader = ({ id }: { id: string }) => {
  const { data: companyConfig } = companyConfigApi.useGetFerryCompanyConfigQuery(id);

  if (companyConfig === undefined) {
    return null;
  }
  const companyConfigData = companyConfig.config;

  const { name } = companyConfigData;
  return (
    <PageHeader
      title={name}
      withBack={isNativeMobile}
      Subtitle={CompaniesSubtitle({ companyConfigData })}
      childLeft={<OperatorImages operators={companyConfigData.operators} />}
    />
  );
};

const CompaniesSubtitle = ({
  companyConfigData,
}: {
  companyConfigData: FerryCompanyCmsExtended;
}) => {
  const { operators } = companyConfigData;
  const operatorNamesList = operators.map((operatorConfig) => operatorConfig.name);

  return (
    <Text.Body2Regular color="monochrome-mid">{`${operatorNamesList.join(
      ' | ',
    )}`}</Text.Body2Regular>
  );
};
