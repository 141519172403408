import {
    KeyVal,
    RouteTripDictionaryClient,
    RouteTripTariffClient,
    TripPriceTariffAccommodation,
} from '@naus-code/naus-client-types';
import { DispatchTariffOptions } from '../types/providerCommon';
import { BasketManagerVehDispatch } from './bm.3.veh.0.dispatch';
import { mapArrayToKeyValue } from '../utils/functions';
import {
    SelectTicketEditTariffItem,
    SelectTicketTariffItem,
} from '../types/clientState/1.pricing/clientState.pricingEditTariff';
import { ClientBasketState, SelectedTicket } from '../types/clientState';

export class BasketManagerTariffsDispatch extends BasketManagerVehDispatch {
    //
    private getDispatchTariffReqPerTrip = (
        options?: DispatchTariffOptions,
    ): KeyVal<TripPriceTariffAccommodation | undefined> => {
        const bmState = this.getBmState();
        return (
            options?.tariffReqPerTrip ??
            mapArrayToKeyValue(
                bmState.routePrice.trips,
                (trip) => trip.key,
                (trip) => trip.tariffReq,
            )
        );
    };

    dispatchTariff = (options?: DispatchTariffOptions) => {
        const bmState = this.getBmState();

        const tariffReqPerTrip = this.getDispatchTariffReqPerTrip(options);

        bmState.routePrice.trips.forEach((trip) => {
            const includeTrip = !!tariffReqPerTrip?.[trip.key];
            if (includeTrip) {
                trip.tariffReq = tariffReqPerTrip[trip.key];
            }
        });

        if (!options?.disablePriceReset) {
            this.resetRoutePriceManagerState();
        }

        if (!options?.disableErrorReset) {
            this.resetRoutePriceErrorState();
        }

        this.dispatchBasketWrapper(options?.state, (state) => {
            // this.setTariffQuoteOptions(state, tariffData, options);
            this.setTariffPricing(state, options);
            // this.setTariffDetails(state, tariffData, options);
            this.validateState(state);
        });
        this.dispatchManager();
    };

    //---------------PRICING---------------//

    private setTariffPricing = (state: ClientBasketState, options?: DispatchTariffOptions) => {
        if (this.skipSelectiveUpdate(options?.selectiveUpdate, 'pricing')) {
            return;
        }
        const bmState = this.getBmState();

        for (let tripIndex = 0; tripIndex < bmState.selectedTrips.length; tripIndex++) {
            const trip = bmState.selectedTrips[tripIndex];
            const tripDic = this.getTripDictionary(trip.key);
            const skipTrip = !options?.tariffReqPerTrip?.[trip.key];
            if (skipTrip) {
                continue;
            }
            //Error
            state.pricing.trips[tripIndex].validation.error = bmState.routePrice.trips[tripIndex].error;

            const tariffItem = this.getPricingTariffItem(options, tripDic);
            state.pricing.trips[tripIndex].tariff = tariffItem;
        }
    };

    private getPricingTariffItem = (
        options: DispatchTariffOptions | undefined,
        tripDic: RouteTripDictionaryClient,
    ): SelectTicketTariffItem | undefined => {
        const bmState = this.getBmState();
        const tariffReq = bmState.routePrice.trips[tripDic.index].tariffReq;
        if (!tariffReq) {
            return undefined;
        }
        const tariffDic = tripDic.tripTariffsDic[tariffReq.tariffCode];
        const { selectedTicket, availableTariffs } = this.getPricingTariffTicket(tariffDic, options, tripDic);
        const tariffItem: SelectTicketTariffItem = {
            tariffId: tariffReq.tariffId,
            //
            tripIndex: tripDic.index,
            tripKey: tripDic.key,
            //
            label: tariffDic.name,
            //
            selectedTicket,
            availableTariffs,
            //
            value: this.formatPrice(bmState.routePrice.trips[tripDic.index].accPrices[tariffReq.tariffId]?.total),
        };
        return tariffItem;
    };

    private getPricingTariffTicket = (
        tariffDic: RouteTripTariffClient,
        _options: DispatchTariffOptions | undefined,
        tripDic: RouteTripDictionaryClient,
    ) => {
        let selectedTicket: SelectedTicket = {
            code: tariffDic.tariffCode,
            //
            label: tariffDic.name,
            description: tariffDic.description,
            //
            customCancelPolicy: tariffDic.cancelPolicy,
            customModifyPolicy: tariffDic.modifyPolicy,
            nonRefundable: tariffDic.nonRefundable,
            nonAmendable: tariffDic.nonAmendable,
            applyToOthers: false,
            value: undefined,
            isUpgrade: undefined,
            includedInPrice: undefined,
        };
        const availableTariffs: SelectTicketEditTariffItem[] = [];
        for (const tariffTicket of tripDic.tripTariffs || []) {
            const isSelected = tariffTicket.tariffCode === tariffDic.tariffCode;
            const mappedTicket: SelectTicketEditTariffItem = {
                code: tariffTicket.tariffCode,
                //
                label: tariffTicket.name,
                description: tariffTicket.description,
                //
                isSelected,
                //
                customCancelPolicy: tariffTicket.cancelPolicy,
                customModifyPolicy: tariffTicket.modifyPolicy,
                nonRefundable: tariffTicket.nonRefundable,
                nonAmendable: tariffTicket.nonAmendable,
            };
            availableTariffs.push(mappedTicket);
        }

        return { selectedTicket, availableTariffs };
    };
}
