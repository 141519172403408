import 'dayjs/locale/de';
import 'dayjs/locale/el';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';

import dayjsBase from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import durationPlugin from 'dayjs/plugin/duration';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isTodayPlugin from 'dayjs/plugin/isToday';
import { i18nManager } from '../lang';

const mutable = {
  dayjs: dayjsBase,
};
mutable.dayjs.extend(isTodayPlugin);
mutable.dayjs.extend(advancedFormat);
mutable.dayjs.extend(isBetweenPlugin);
mutable.dayjs.extend(customParseFormat);
mutable.dayjs.extend(durationPlugin);
mutable.dayjs.Ls.en.weekStart = 1;

export const updateDayjsLocale = () => {
  mutable.dayjs.locale(i18nManager.locale);
  mutable.dayjs = mutable.dayjs;
};

export const dayjs = mutable.dayjs;
export type Dayjs = dayjsBase.Dayjs;
export * from './date.utils';
