import {
  PurchaseClientAutoUpdateRequest,
  TicketClient,
} from '@naus-code/naus-admin-types';

import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';

import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { useEffect } from 'react';
import {
  ManualModificationProvider,
  useManualStore,
} from '../Manual/ManualProvider/Modify.ManualTrip.Provider';
import { purchaseActionManager } from '../../Common/ActionPanel.utils';
import {
  createAutoImportPurchases,
  mapPurchaseRetrieveToClient,
  mapTicketToClient,
} from '../../ImportPurchase/ActionPanel.Import.utils';
import { purchaseActionApi } from '../../../PurchaseActions.api';
import { ModifyModalKey } from '../Modify.Panel';
import { ManualModifyPurchaseInfo } from '../Manual/Modify.ManualTripManager';

const AutoUpdateEditModalKey = 'AutoUpdateEditModal-Key';

export const useAutoUpdateEditModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (body: PurchaseClientAutoUpdateRequest) => {
      openModal(<AutoUpdateEditContainer body={body} />, {
        key: AutoUpdateEditModalKey,
        type: 'action-sheet',
      });
    },
    close: () => closeModal(AutoUpdateEditModalKey),
  };
};

const AutoUpdateEditContainer = ({ body }: { body: PurchaseClientAutoUpdateRequest }) => {
  return (
    <ManualModificationProvider>
      <View p="2.5">
        <AutoUpdateEditModal body={body} />
      </View>
    </ManualModificationProvider>
  );
};

const AutoUpdateEditModal = ({ body }: { body: PurchaseClientAutoUpdateRequest }) => {
  const [purchaseRequest] = purchaseActionManager.state.useGlobalState('purchaseRequest');
  if (!purchaseRequest) {
    return null;
  }

  const purchaseUnedited = {
    ...purchaseRequest,
    tickets: purchaseRequest?.tickets.filter((ticket) => !ticket.updateType),
  };

  const purchaseToEdit = {
    ...purchaseRequest,
    tickets: purchaseRequest?.tickets.filter((ticket) => !!ticket.updateType),
  };

  const [selectedPurchase, setSelectedReservation] =
    purchaseActionManager.state.useGlobalState('selectedReservation');

  const [_selectedTickets, setSelectedTickets] =
    purchaseActionManager.state.useGlobalState('selectedTickets');

  const mappedTickets = purchaseToEdit.tickets
    .map((ticket, index) => mapTicketToClient(ticket, purchaseToEdit, index + ''))
    .filter((ticket) => !!ticket);

  const ticketIds = mappedTickets.map((ticket) => ticket?._id);

  useEffect(() => {
    setSelectedReservation(purchaseToEdit.companyReservationCode);
    setSelectedTickets(ticketIds as string[]);
  }, [selectedPurchase]);

  const { closeModal } = useModal();
  const manualModStore = useManualStore();
  const modificationForms = manualModStore((s) => s.modificationForms);

  const [updatePurchase, { isLoading: isAddingPurchase }] =
    purchaseActionApi.useUpdatePurchaseProviderMutation();
  const purchaseProcessed = mapPurchaseRetrieveToClient(
    purchaseToEdit,
    mappedTickets as TicketClient[],
  );

  const onConfirm = async () => {
    const purchasesToUpdateData = createAutoImportPurchases(
      modificationForms,
      purchaseToEdit,
    );

    if (!purchasesToUpdateData) {
      showToast('Cannot get purchase form data', { type: 'error' });
      return;
    }

    const { autoUpdatePurchase } = purchasesToUpdateData;

    const response = await updatePurchase({
      ...body,
      purchase: {
        ...autoUpdatePurchase,
        tickets: [...purchaseUnedited.tickets, ...autoUpdatePurchase.tickets],
      },
    });

    // const response = {};

    handleResponse({
      response,
      onError: () => {
        showToast('Importing failed!', { type: 'error' });
      },
      onSuccess: () => {
        closeModal(ModifyModalKey);
        closeModal(AutoUpdateEditModalKey);
      },
    });
    setSelectedReservation('');
    return response;
  };

  return (
    <Modal.Container style={{ maxHeight: 800 }}>
      <Modal.Header
        title="Confirm all details are correct and accurate"
        onPress={() => closeModal(AutoUpdateEditModalKey)}
      />
      <Modal.Body scroll>
        <ManualModifyPurchaseInfo purchase={purchaseProcessed} defaultShowAllVessels />
      </Modal.Body>
      <Modal.Footer onConfirm={() => onConfirm()} isLoading={isAddingPurchase} />
    </Modal.Container>
  );
};
