import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { isErrorResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import {
  CustomerClientPassenger,
  CustomerClientVehicleV2,
  RoutePriceV2,
  RouteTrip,
  RouteTripNoDetails,
} from '@naus-code/naus-client-types';
import { PropsWithChildren, useEffect, useState } from 'react';

import { getVerifyLoyaltyApi } from '~/components/BasketManager/BasketManager.api';
import { useBasketAttentionManager } from '~/components/BasketManager/BasketManager.Attention';
import {
  useBasketManager,
  useBasketManagerStore,
} from '~/components/BasketManager/BasketManager.Provider';
import { BasketTickets } from '~/components/BasketManager/BasketManager.Tickets';
import { PassengerList } from '~/components/BasketManager/utils/ticketPricing/TicketsPricing.TripItem';
import { searchApi } from '~/components/Search/Search.api';
import { fetchManager } from '~/utils/network.utils';

import { ModifyPanelProps, useModifyData } from './Modify.utils';

interface ModifyProps {
  initialRoutePrice: RoutePriceV2;
  purchaseId: string;
  ticketIds: string[];
  routeTrip: RouteTrip;
  // tripKeys: string[];
}

export const ModifyBasketPanelQuery = (props: ModifyPanelProps) => {
  const {
    selectedTickets,
    selectedRoute,
    manuallySelectedRoute,
    supportRequest,
    error,
    initialRoutePrice,
    isLoading,
  } = useModifyData(props);

  const noSelectedRoute = !(supportRequest?.requestOptions || manuallySelectedRoute);

  if (error && !isLoading && selectedTickets.length !== 0 && !noSelectedRoute) {
    return (
      <View align="center" justify="center" flex={1}>
        <Text.Body2Regular align="center" color="warning-mid">
          {error}
        </Text.Body2Regular>
      </View>
    );
  }

  if (!initialRoutePrice || !selectedRoute) {
    return null;
  }

  if (noSelectedRoute) {
    return null;
  }

  return (
    <BasketManagerInitiator
      key={selectedRoute.key}
      initialRoutePrice={initialRoutePrice}
      ticketIds={selectedTickets}
      purchaseId={props.purchaseId}
      routeTrip={selectedRoute}
    >
      <ModifyBasketPanel routeTrip={selectedRoute} />
    </BasketManagerInitiator>
  );
};

export const ModifyBasketPanel = ({ routeTrip }: { routeTrip: RouteTrip }) => {
  return (
    <DelayRender>
      <VStack space="1">
        <BasketTickets routeTrip={routeTrip} />
      </VStack>
    </DelayRender>
  );
};

// function getVehicleTypesCount(vehicles: RoutePriceVehicleV2[]) {
//   const car = vehicles.filter((vehicles) => vehicles.category === 'car').length;
//   const moto = vehicles.filter((vehicles) => vehicles.category === 'moto').length;
//   const camper = vehicles.filter((vehicles) => vehicles.category === 'camper').length;
//   const bus = vehicles.filter((vehicles) => vehicles.category === 'bus').length;
//   const bicycle = vehicles.filter((vehicles) => vehicles.category === 'bike').length;
//   const trailer = vehicles.filter((vehicles) => vehicles.category === 'trailer').length;
//   return {
//     car,
//     moto,
//     camper,
//     bus,
//     bicycle,
//     trailer,
//   };
// }

const BasketManagerInitiator = (props: PropsWithChildren<ModifyProps>) => {
  const basketManager = useBasketManager();
  const [ready, setReady] = useState(false);
  const [getNewRoutePrice, { isLoading: isLoadingRoutePrice }] =
    searchApi.useVerifyPricePurchaseModifyMutation();

  useEffect(() => {
    // Fill in the stuff
    // Get information from api/server + modification details
    basketManager.resumeState({
      // query: {
      //   adult: props.initialRoutePrice.passengers.length,
      //   child: 0,
      //   pet: props.initialRoutePrice.pets.length,
      //   ...getVehicleTypesCount(props.initialRoutePrice.vehicles),
      // },
      configOptions: undefined,
      savedPassengers: props.initialRoutePrice.passengers.map((p) => {
        return {
          id: p.id,
          name: p.name,
          lastName: p.lastName,
          sex: p.sex ?? 'M',
          birthDate: p.birthDate,
        } as CustomerClientPassenger;
      }),
      savedVehicles: props.initialRoutePrice.vehicles.map(
        (v) =>
          ({
            id: v.id,
            category: v.category,
            plateNumber: v.plateNumber,
            size: v.size,
            model: v.model,
            engineCategory: v.engineCategory,
            nickName: v.nickName,
          }) as CustomerClientVehicleV2,
      ),
      savedPets: [],
      selectedTrips: [props.routeTrip],
      routePrice: structuredClone(props.initialRoutePrice),
      onVerifyPrice: async (routePrice) => {
        // 1. Put in a verify price api call , give routePrice to G server and then return response to BM
        // have the hook on the top, and call it manually?
        const newPrice = await getNewRoutePrice({
          ticketIds: props.ticketIds,
          purchaseId: props.purchaseId,
          route: routePrice,
        });
        if (isErrorResponse(newPrice)) {
          // TODO: show toast whotee
          throw new Error('no work');
        }

        // TODO: attention show Attention modal

        return structuredClone(newPrice.data);
      },
      onVerifyLoyalty: getVerifyLoyaltyApi(fetchManager),
    });
    basketManager.fetchAndUpdatePrices();
    setReady(true);
  }, [props.purchaseId, props.ticketIds, props.initialRoutePrice]);

  useBasketAttentionManager({
    options: {
      currency: props.initialRoutePrice.ccy,
      tripKeys: [props.routeTrip.key],
    },
  });

  return (
    <DelayRender isLoading={!ready || isLoadingRoutePrice}>{props.children}</DelayRender>
  );
};

export const Modals = ({
  manuallySelectedRoute,
}: {
  manuallySelectedRoute?: RouteTrip | RouteTripNoDetails;
}) => {
  const useBmStore = useBasketManagerStore();
  const basketValidation = useBmStore((s) => s.basket.pricing.validation);
  if (!manuallySelectedRoute || basketValidation.status !== 'valid') {
    return null;
  }
  return <PassengerList trip={manuallySelectedRoute} tripIndex={0} />;
};
