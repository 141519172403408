import { DelayRender } from '@droplet-tech-code/core-elements/module/ui/Delay/Delay';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { SelectInputBase } from '@droplet-tech-code/core-elements/module/ui/Inputs/SelectInput/SelectInput';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { Toggle } from '@droplet-tech-code/core-elements/module/ui/Toggle/Toggle';
import { ScrollView, View } from '@droplet-tech-code/core-elements/module/ui/View';
import {
  getErrorResponse,
  isRTKErrorResponse,
} from '@droplet-tech-code/core-elements/module/utils/error';
import {
  isNativeMobile,
  isWeb,
  notEmpty,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import {
  BookingPurchaseRetrieveClient,
  GetPurchaseResponse,
  PurchaseClientAutoUpdateRequest,
  PurchaseClientAutoUpdateResponse,
  PurchaseUpdate,
} from '@naus-code/naus-admin-types';
import React, { useEffect, useState } from 'react';

import { ErrorFeedback } from '~/components/Feedback/Error.Feedback';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { PurchaseListItemInternalComponent } from '~/screens/Support/Purchases/Purchase.ListItem';
import { purchaseActionApi } from '~/screens/Support/Purchases/PurchaseActions/PurchaseActions.api';
import { getTicketName, TicketDetails } from '~/screens/Support/Purchases/PurchaseItem';
import { handleRTKResponse } from '~/utils/network.utils';
import { translate } from '~/utils/translation.utils';

import { purchaseApi } from '../Purchases.api';

export const ModalPurchaseFromProviderCompare = 'usePurchaseFromProviderCompareModal';

export const PurchaseFromProviderCompare = ({
  purchaseId,
  resCode,
}: {
  purchaseId: string;
  resCode: string;
}) => {
  return <PurchaseItemComponent purchaseId={purchaseId} resCode={resCode} />;
};

const PurchaseItemComponent = ({
  purchaseId,
  resCode,
}: {
  purchaseId: string;
  resCode: string;
}) => {
  const {
    data: providerData,
    isLoading,
    isFetching,
  } = purchaseApi.useGetPurchaseRetrieveFromProviderQuery(
    {
      purchaseId,
      resCode,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  // const {
  //   data: internalPurchase,
  //   isLoading: isLoadingInternalPurchase,
  //   isFetching: isFetchingInternal,
  // } = purchaseApi.useGetPurchaseQuery(purchaseId, {
  //   refetchOnMountOrArgChange: true,
  // });

  const {
    data: internalPurchase,
    isLoading: isLoadingInternalPurchase,
    isFetching: isFetchingInternal,
  } = purchaseApi.useGetPurchaseQuery(purchaseId);

  const providerPurchases = providerData?.reservation.purchases;
  return (
    <DelayRender
      isLoading={
        isFetching || isFetchingInternal || isLoading || isLoadingInternalPurchase
      }
    >
      {providerPurchases?.length && internalPurchase ? (
        <AutoUpdateModal
          providerPurchases={providerPurchases}
          internalPurchase={internalPurchase}
        />
      ) : (
        <ErrorFeedback />
      )}
    </DelayRender>
  );
};

const AttachKey = '@A';
const LinkKey = '@L';
const ImportKey = 'IMPORT';
export const DoNothingKey = 'N/A';

export const processPurchaseRequest = ({
  providerPurchase,
  linkValues,
  internalPurchase,
}: {
  providerPurchase: BookingPurchaseRetrieveClient;
  internalPurchase: GetPurchaseResponse;
  linkValues: { [key: string]: string };
}): PurchaseUpdate => {
  return {
    ...providerPurchase,
    tickets: providerPurchase.tickets.map((providerTicket, ix) => {
      const linkValue = linkValues[ix]; // NA, IMPORT, ticketId

      if (linkValue === ImportKey) {
        return {
          ...providerTicket,
          updateType: 'import',
        };
      }

      const foundInternalTicket = internalPurchase.purchase.tickets.find((t) => {
        return t._id === linkValue.slice(2);
      });

      if (foundInternalTicket) {
        if (linkValue.slice(0, 2) === AttachKey) {
          return {
            ...providerTicket,
            // updateLinkId: foundInternalTicket._id,
            updateType: 'import',
            parentTicketId: foundInternalTicket._id,
          };
        }

        if (linkValue.slice(0, 2) === LinkKey) {
          return {
            ...providerTicket,
            updateLinkId: foundInternalTicket._id,
            updateType: 'update',
          };
        }
      }

      return providerTicket;
    }),
  };
};

export const TripSelector = ({
  providerPurchases,
  providerPurchaseIndex,
  setProviderPurchaseIndex,
}: {
  providerPurchases: BookingPurchaseRetrieveClient[];
  providerPurchaseIndex: number;
  setProviderPurchaseIndex: (index: number) => void;
}) => (
  <SelectInputBase
    type="single-select"
    options={providerPurchases.map((p, ix) => ({
      label: `(${ix + 1}) ${p.originDisplay} (${p.origin}) -> ${p.destinationDisplay} (${
        p.destination
      }) ${p.departureDateTime}`,
      value: String(ix),
    }))}
    inputContainerStyle={
      isWeb
        ? {
            minWidth: 420,
          }
        : {
            minWidth: 300,
          }
    }
    values={[String(providerPurchaseIndex)]}
    noFeedback
    feedback=""
    setValues={(selectedPurchaseIndex) => {
      if (selectedPurchaseIndex?.[0]) {
        setProviderPurchaseIndex(Number(selectedPurchaseIndex[0]));
      }
    }}
  />
);

export const AutoUpdateModal = ({
  providerPurchases,
  internalPurchase,
}: {
  providerPurchases: BookingPurchaseRetrieveClient[];
  internalPurchase: GetPurchaseResponse;
}) => {
  const [silentOn, setSilentOn] = useState(false);
  const [refreshPDF, setRefreshPdf] = useState(false);

  const [providerPurchaseIndex, setProviderPurchaseIndex] = useState<number>(0);
  const providerPurchase = providerPurchases[providerPurchaseIndex];
  const [linkValues, setLinkValues] = useState<{
    [key: string]: string;
  }>(
    Object.assign(
      {},
      ...providerPurchase.tickets.map((_, tIx) => ({
        [String(tIx)]: DoNothingKey,
      })),
    ),
  );

  const { closeModal, openModal } = useModal();

  const [modifyPurchase, { isLoading }] =
    purchaseActionApi.useUpdatePurchaseProviderMutation();

  return (
    <VStack
      flex={1}
      style={[
        isWeb && {
          maxHeight: window.innerHeight * 0.85,
        },
      ]}
    >
      <Modal.Header
        title=""
        divider
        onPress={() => {
          closeModal(ModalPurchaseFromProviderCompare);
        }}
        children={
          isWeb
            ? TripSelector({
                providerPurchases,
                providerPurchaseIndex,
                setProviderPurchaseIndex,
              })
            : null
        }
      />
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingTop: isNativeMobile ? 12 : undefined,
        }}
      >
        {isNativeMobile
          ? TripSelector({
              providerPurchases,
              providerPurchaseIndex,
              setProviderPurchaseIndex,
            })
          : null}
        <AutoUpdateInner
          internalPurchase={internalPurchase}
          linkValues={linkValues}
          setLinkValues={setLinkValues}
          providerPurchase={providerPurchase}
          setSilentOn={setSilentOn}
        />
      </ScrollView>

      <Modal.Footer
        divider
        isLoading={isLoading}
        confirm={translate(
          silentOn
            ? 'purchases.purchaseTool.purchaseCompare.updateSilent'
            : 'purchases.purchaseTool.purchaseCompare.update',
        )}
        onConfirm={async () => {
          const response = await modifyPurchase({
            supportId: '',
            purchaseId: internalPurchase.purchase._id,
            silentUpdate: silentOn,
            refreshPdf: refreshPDF,
            purchase: processPurchaseRequest({
              internalPurchase,
              providerPurchase,
              linkValues,
            }),
            providerOptions: {} as PurchaseClientAutoUpdateRequest['providerOptions'], // TODO: fix this highjacking
          });

          handleRTKResponse({
            response,
            onSuccess: (data) => {
              if (isRTKErrorResponse(data)) {
                showToast(getErrorResponse(data.error).message, {
                  type: 'error',
                });
                return;
              }
              closeModal(ModalPurchaseFromProviderCompare);

              openModal(
                <VStack>
                  <AutoUpdateResponseMessage data={data.data} />
                  <Modal.Footer
                    divider
                    confirm={translate('common.close')}
                    onConfirm={() => {
                      closeModal('compare-result');
                    }}
                  />
                </VStack>,
                { key: 'compare-result' },
              );
              // data.data.result.outcome;
              // data.data.result.changesDetected;
            },
          });
        }}
        onCancel={() => {
          // closeModal(TabModalKey);
          closeModal(ModalPurchaseFromProviderCompare);
        }}
      >
        <SwitchStack space="3">
          <HStack space="1">
            <Toggle
              color="primary-mid"
              value={silentOn}
              onChange={(v) => setSilentOn(v)}
            />
            <Text.Body2Regular color="monochrome-extraDark">
              {translate('purchases.purchaseTool.purchaseCompare.silentUpdate')}
            </Text.Body2Regular>
          </HStack>

          <HStack space="1">
            <Toggle
              color="primary-mid"
              value={refreshPDF}
              onChange={(v) => setRefreshPdf(v)}
            />
            <Text.Body2Regular color="monochrome-extraDark">
              {translate('purchases.purchaseTool.purchaseCompare.refreshPDF')}
            </Text.Body2Regular>
          </HStack>
        </SwitchStack>
      </Modal.Footer>
    </VStack>
  );
};

export const AutoUpdateResponseMessage = ({
  data,
}: {
  data: PurchaseClientAutoUpdateResponse;
}) => {
  return (
    <VStack p="3" space="3">
      <VStack>
        <Text.Small color="monochrome-dark">
          {translate('purchases.purchaseTool.purchaseCompare.outcome')}
        </Text.Small>
        <Text.Body2Medium>{data.result.outcome}</Text.Body2Medium>
      </VStack>

      <VStack>
        <Text.Small color="monochrome-dark">
          {translate('purchases.purchaseTool.purchaseCompare.changes')}
        </Text.Small>
        {data.result.changesDetected ? (
          <VStack space="1.5">
            {Object.entries(data.result.changesDetected).map((item, ix) => {
              return (
                <HStack key={ix}>
                  <Text.Small color="monochrome-mid">{item[0]}</Text.Small>
                  {' -> '}
                  <Text.Body2Regular>{String(item[1])}</Text.Body2Regular>
                </HStack>
              );
            })}
          </VStack>
        ) : (
          <Text.Body1Regular color="monochrome-mid">
            {translate('purchases.purchaseTool.purchaseCompare.noChanges')}
          </Text.Body1Regular>
        )}
      </VStack>
    </VStack>
  );
};

export const AutoUpdateInner = ({
  internalPurchase,
  linkValues,
  providerPurchase,
  setLinkValues,
  setSilentOn,
}: {
  internalPurchase: GetPurchaseResponse;
  linkValues: { [key: string]: string };
  setLinkValues: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
  setSilentOn: (set: boolean) => void;
  providerPurchase: BookingPurchaseRetrieveClient;
}) => {
  const imports = Object.values(linkValues)
    .map((v, ix) => (v === ImportKey ? providerPurchase.tickets[ix] : undefined))
    .filter(notEmpty);

  useEffect(() => {
    setLinkValues(
      Object.assign(
        {},
        ...providerPurchase.tickets.map((_, tIx) => ({
          [String(tIx)]: DoNothingKey,
        })),
      ),
    );
    setSilentOn(false);
  }, [providerPurchase]);

  return (
    <SwitchStack space="3" align={isWeb ? 'flex-start' : undefined}>
      <VStack space="3" p="4" flex={1}>
        <Text.Body2Regular>
          {translate('purchases.purchaseTool.purchaseCompare.currentPurchase')}
        </Text.Body2Regular>
        <VStack bg="primary-base" br={12} p="3">
          <PurchaseListItemInternalComponent
            purchase={internalPurchase.purchase}
            compareMode
          />
        </VStack>
        <DottedLine />
        <VStack space="4">
          {internalPurchase.purchase.tickets.map((ticket, iy) => {
            const linkedValues = Object.values(linkValues);
            const mappedValues = linkedValues.map((lv, providerIndex) => {
              if (lv === ImportKey || lv === DoNothingKey) {
                return {
                  key: lv,
                  ticketId: '',
                  ticket: undefined,
                };
              }

              const key = lv.slice(0, 2);
              const ticketId = lv.slice(2);

              return {
                key,
                ticketId,
                ticket: providerPurchase.tickets[providerIndex],
              };
            });

            const foundTicketsMatched = mappedValues.filter(
              (v) => v.ticketId === ticket._id,
            );

            const linkedTickets = foundTicketsMatched.filter(
              (t) => t.key === LinkKey && !!t.ticket,
            );

            const attachedTickets = foundTicketsMatched.filter(
              (t) => t.key === AttachKey && !!t.ticket,
            );

            return (
              <VStack
                space="2"
                key={iy}
                // style={{ height: 105 }}
              >
                <TicketDetails
                  ticket={ticket}
                  ccySymbol={internalPurchase.purchase.ccySymbol}
                />

                {linkedTickets.length ? (
                  <VStack>
                    {linkedTickets.map((t, ix) => {
                      return (
                        <View flex={1} key={ix}>
                          <View bg="secondary-base" px="3" py="1" br={12}>
                            <Text.Body2Medium color="secondary-dark">
                              {translate(
                                'purchases.purchaseTool.purchaseCompare.replacedWith',
                                {
                                  name: getTicketName(t.ticket!).name,
                                },
                              )}
                            </Text.Body2Medium>
                          </View>
                        </View>
                      );
                    })}
                  </VStack>
                ) : null}

                {attachedTickets.length ? (
                  <VStack pl="8">
                    {attachedTickets.map((t, ix) => {
                      return (
                        <View flex={1} key={ix}>
                          <VStack bg="success-base" px="3" py="1" br={12} space="3">
                            <TicketDetails
                              ticket={t.ticket!}
                              ccySymbol={internalPurchase.purchase.ccySymbol}
                            />
                            <Text.Body2Medium
                              color="success-dark"
                              style={{ paddingLeft: 12 }}
                            >
                              {translate(
                                'purchases.purchaseTool.purchaseCompare.attachedAndImported',
                              )}
                            </Text.Body2Medium>
                          </VStack>
                        </View>
                      );
                    })}
                  </VStack>
                ) : null}

                <View>
                  {internalPurchase.purchase.tickets.length - 1 === iy ? null : (
                    <DottedLine />
                  )}
                </View>
              </VStack>
            );
          })}

          {imports.length > 0 ? <DottedLine /> : null}
          {imports.length
            ? imports.map((importItem, ix) => {
                return (
                  <VStack bg="success-base" br={12} key={ix}>
                    <TicketDetails
                      ticket={importItem}
                      ccySymbol={internalPurchase.purchase.ccySymbol}
                    />
                    <View py="3" px="6">
                      <Text.Body2Medium color="success-dark">
                        {translate('purchases.purchaseTool.purchaseCompare.imported')}
                      </Text.Body2Medium>
                    </View>
                  </VStack>
                );
              })
            : null}
        </VStack>
      </VStack>

      <VStack space="3" p="4" flex={1}>
        <Text.Body2Regular>
          {translate('purchases.purchaseTool.purchaseCompare.newProviderPurchase')}
        </Text.Body2Regular>
        <VStack bg="success-base" br={12} p="3">
          <PurchaseListItemInternalComponent
            purchase={{
              ...providerPurchase,
              ccySymbol: providerPurchase.companyCcy,
              value: providerPurchase.value,
            }}
            compareMode
          />
        </VStack>
        <DottedLine />

        <VStack space="3">
          {providerPurchase.tickets.map((providerTicket, iy) => {
            return (
              <VStack
                space="2"
                key={iy}
                // style={{ height: 105 }}
              >
                <TicketDetails
                  ticket={providerTicket}
                  ccySymbol={internalPurchase.purchase.ccySymbol}
                />

                <SelectInputBase
                  type="single-select"
                  values={[linkValues[String(iy)]]}
                  noFeedback
                  feedback=""
                  setValues={(singleValue) => {
                    setLinkValues({
                      ...linkValues,
                      [String(iy)]: singleValue?.[0] || DoNothingKey,
                    });
                  }}
                  options={[
                    {
                      label: translate(
                        'purchases.purchaseTool.purchaseCompare.doNothing',
                      ),
                      value: DoNothingKey,
                    },
                    {
                      label: translate('purchases.purchaseTool.purchaseCompare.import'),
                      value: ImportKey,
                    },
                    ...internalPurchase.purchase.tickets.map((t) => {
                      return {
                        label: translate(
                          'purchases.purchaseTool.purchaseCompare.linkTo',
                          {
                            name: getTicketName(t).name,
                          },
                        ),
                        value: LinkKey + t._id,
                      };
                    }),
                    ...(providerTicket.ticketType === 'Extra'
                      ? internalPurchase.purchase.tickets.map((t) => {
                          return {
                            label: translate(
                              'purchases.purchaseTool.purchaseCompare.attach',
                              {
                                name: getTicketName(t).name,
                              },
                            ),
                            value: AttachKey + t._id,
                          };
                        })
                      : []),
                  ]}
                />

                {providerPurchase.tickets.length - 1 === iy ? null : <DottedLine />}
              </VStack>
            );
          })}
        </VStack>
      </VStack>
    </SwitchStack>
  );
};
