import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { isWeb } from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { FerryCompanyCmsExtended } from '@naus-code/naus-config-types';

import { CardWithHeader } from '~/components/Card/CardWithHeader';
import { useUpdateFerryCompanyModal } from '~/components/Config/CompanyConfig/Modals/Company/Modals';
import { SwitchStack } from '~/components/Stack/SwitchStack';
import { AppText } from '~/components/Text';
import { translate } from '~/utils/translation.utils';

export const CompanyInfoCard = ({
  companyInfo,
}: {
  companyInfo: FerryCompanyCmsExtended;
}) => {
  const { id, providerConfig, countries, currency, commission } = companyInfo;

  const { providerCode, provider } = providerConfig;

  const { open: openUpdateCompanyInfo } = useUpdateFerryCompanyModal(id);
  return (
    <CardWithHeader
      headerText={translate('companyConfig.companyInfo')}
      iconRightType="edit"
      iconRightOnPress={openUpdateCompanyInfo}
    >
      <SwitchStack p="2.5" space="2.5">
        <VStack space="2.5" flex={isWeb ? 1 : undefined}>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.id')}
            </AppText.ExtraSmall>
            <Text.Small>{id}</Text.Small>
          </HStack>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.provider')}
            </AppText.ExtraSmall>
            <Text.Small>{provider.id}</Text.Small>
          </HStack>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.providerCode')}
            </AppText.ExtraSmall>
            <Text.Small>{providerCode}</Text.Small>
          </HStack>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.countries')}
            </AppText.ExtraSmall>
            <Text.Small>{`[${countries.join(',')}]`}</Text.Small>
          </HStack>
        </VStack>
        <VStack space="2.5" flex={isWeb ? 1 : undefined}>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.currency')}
            </AppText.ExtraSmall>
            <Text.Small>{currency}</Text.Small>
          </HStack>
          <HStack>
            <AppText.ExtraSmall flex={1} color="monochrome-extraDark">
              {translate('companyConfig.commission')}
            </AppText.ExtraSmall>
            <Text.Small>{commission.percentage}</Text.Small>
          </HStack>
        </VStack>
      </SwitchStack>
    </CardWithHeader>
  );
};
