import {
    KeyVal,
    Optional,
    RoutePriceVehicleV2,
    RouteTripDictionaryClient,
    RouteVehicleValidation,
    TripPriceVehicleAccommodationV2,
} from '@naus-code/naus-client-types';
import { BasketManagerPetsDispatch } from './bm.3.pet.0.dispatch';
import { DispatchVehicleOptions } from '../types/providerCommon';
import {
    AvailableDiscount,
    AvailableDriver,
    ClientBasketState,
    ClientStateVehicleTripDetail,
    SelectTicketVehicleItem,
    SelectedDriver,
    SelectedTicket,
    VehicleListItem,
} from '../types/clientState';
import { FieldInput, VehicleFieldNames } from '../types/clientState/2.tipDetails/clientState.fieldInputs';
import { mapArrayToKeyValue } from '../utils/functions';

export class BasketManagerVehDispatch extends BasketManagerPetsDispatch {
    //
    private getDispatchVehicleReqPerTrip = (
        vehicleData: RoutePriceVehicleV2,
        options?: DispatchVehicleOptions,
    ): KeyVal<TripPriceVehicleAccommodationV2> => {
        const bmState = this.getBmState();
        return (
            options?.vehicleReqPerTrip ??
            mapArrayToKeyValue(
                bmState.routePrice.trips,
                (trip) => trip.key,
                (trip) => trip.vehicleAccReq[vehicleData.index],
            )
        );
    };

    dispatchVehicle = (vehicleData: RoutePriceVehicleV2, options?: DispatchVehicleOptions) => {
        const bmState = this.getBmState();
        const vehicleIndex = vehicleData.index;

        const vehicleReqPerTrip = this.getDispatchVehicleReqPerTrip(vehicleData, options);

        bmState.routePrice.vehicles[vehicleIndex] = vehicleData;
        const tripKeys = Object.keys(vehicleReqPerTrip);
        bmState.routePrice.trips.forEach((trip) => {
            // const includeTrip = options?.tripKeys?.indexOf(trip.key) !== -1;
            const includeTrip = tripKeys?.indexOf(trip.key) !== -1;
            if (includeTrip) {
                trip.vehicleAccReq[vehicleIndex] = vehicleReqPerTrip[trip.key];
            }
        });

        if (!options?.disablePriceReset) {
            this.resetRoutePriceManagerState();
        }

        if (!options?.disableErrorReset) {
            this.resetRoutePriceErrorState();
        }

        this.dispatchBasketWrapper(options?.state, (state) => {
            if (!options?.disablePriceReset) {
                this.resetPrices({ state });
            }
            this.setVehicleQuoteOptions(state, vehicleData, options);
            this.setVehiclePricing(state, vehicleData, options);
            this.setVehicleDetails(state, vehicleData, options);
            this.validateState(state);
        });
        this.dispatchManager();
        return vehicleData;
    };

    //---------------QUOTE_OPTIONS---------------//
    private setVehicleQuoteOptions = (
        state: ClientBasketState,
        vehicleData: RoutePriceVehicleV2,
        options?: DispatchVehicleOptions,
    ) => {
        if (this.skipSelectiveUpdate(options?.selectiveUpdate, 'quoteOptions')) {
            return;
        }
        const bmState = this.getBmState();
        const vehicleItem = this.getQuoteVehicleItem(vehicleData);
        state.quoteOptions.query = this.getQueryBreakdownFromRoutePrice(bmState.routePrice);
        state.quoteOptions.vehicles.unselectedList = [...bmState.unselectedVehicles];
        state.quoteOptions.vehicles.list[vehicleData.index] = vehicleItem;
        state.quoteOptions.vehicles.canRemoveVehicle = bmState.basketDataProcessed.vehicles.required
            ? bmState.routePrice.vehicles.length > 1
            : bmState.routePrice.vehicles.length > 0;
        state.quoteOptions.vehicles.canAddVehicle =
            bmState.routePrice.vehicles.length < bmState.basketDataProcessed.vehicles.limit;
        state.quoteOptions.vehicles.limit = bmState.basketDataProcessed.vehicles.limit;
    };

    private getQuoteVehicleItem = (
        vehicleData: RoutePriceVehicleV2,
        // options?: DispatchVehicleOptions,
    ): VehicleListItem => {
        return {
            vehicleId: vehicleData.id,
            index: vehicleData.index,
            //
            category: vehicleData.category,
            //
            label: this.vehicleGetLabelOrCategoryWithDetails(vehicleData),
            //
            hasRoofBox: !!vehicleData.roofData,
            hasTrailer: !!vehicleData.trailerData,
            hasSideCar: !!vehicleData.sideCarData,
        };
    };

    //---------------PRICING---------------//
    private setVehiclePricing = (
        state: ClientBasketState,
        vehicleData: RoutePriceVehicleV2,
        options?: DispatchVehicleOptions,
    ) => {
        if (this.skipSelectiveUpdate(options?.selectiveUpdate, 'pricing')) {
            return;
        }
        const bmState = this.getBmState();

        for (let tripIndex = 0; tripIndex < bmState.selectedTrips.length; tripIndex++) {
            const trip = bmState.selectedTrips[tripIndex];
            const tripDic = this.getTripDictionary(trip.key);
            const skipTrip = options?.vehicleReqPerTrip && !options.vehicleReqPerTrip?.[trip.key];
            if (skipTrip) {
                continue;
            }
            //Error
            state.pricing.trips[tripIndex].validation.error = bmState.routePrice.trips[tripIndex].error;

            const vehicleItem = this.getPricingVehicleItem(vehicleData, options, tripDic);
            state.pricing.trips[tripIndex].vehicles[vehicleData.index] = vehicleItem;
        }
    };

    private getPricingVehicleItem = (
        vehicleData: RoutePriceVehicleV2,
        options: DispatchVehicleOptions | undefined,
        tripDic: RouteTripDictionaryClient,
    ): SelectTicketVehicleItem => {
        const { selectedTicket } = this.getPricingVehicleTicket(vehicleData, options, tripDic);

        const { selectedDriver, availableDrivers } = this.getPricingVehicleDriver(vehicleData, options, tripDic);
        const { selectedDiscount, availableDiscounts } = this.getVehicleDiscounts(vehicleData, tripDic);
        const bmState = this.getBmState();
        const vehicleItem: SelectTicketVehicleItem = {
            vehicleId: vehicleData.id,
            index: vehicleData.index,
            //
            tripIndex: tripDic.index,
            tripKey: tripDic.key,
            //
            category: vehicleData.category,
            label: this.vehicleGetIndex(vehicleData),
            description: this.vehicleGetLabelOrCategory(vehicleData),
            //
            selectedTicket,
            availableTickets: [selectedTicket],
            //
            selectedDiscount,
            availableDiscounts,
            //
            selectedDriver,
            availableDrivers,
            //
            //TODO Vehicle Extras
            selectedExtras: [],
            availableExtras: [],
            //
            // hasExtraData: !!Object.keys(vehicleData.extraPriceData || {}).length,
            hasExtraData: false,
            requiresExtraData: false,
            //
            value: this.formatPrice(bmState.routePrice.trips[tripDic.index].accPrices[vehicleData.id]?.total),
        };
        return vehicleItem;
    };

    private getVehicleDiscounts = (
        vehicleData: RoutePriceVehicleV2,
        tripDic: RouteTripDictionaryClient,
    ): {
        selectedDiscount?: AvailableDiscount;
        availableDiscounts: AvailableDiscount[];
    } => {
        const bmState = this.getBmState();
        const vehicleReq = bmState.routePrice.trips[tripDic.index].vehicleAccReq[vehicleData.index];

        const discounts = tripDic.vehicleCatAccDic[vehicleReq.vehicleData.category].discounts;

        let selectedDiscount: AvailableDiscount | undefined = undefined;
        const availableDiscounts: AvailableDiscount[] = [];
        const defaultDiscount: AvailableDiscount = {
            code: this.STANDARD_FARE,
            //
            label: this.trns('app.ticketSelection.standard'),
            //
            isSelected: !vehicleReq.vehicleData.discountCode,
            isDisabled: false,
            isStandard: true,
        };
        if (defaultDiscount.isSelected) {
            selectedDiscount = defaultDiscount;
        }
        availableDiscounts.push(defaultDiscount);

        for (const discount of discounts) {
            const isSelected = vehicleReq.vehicleData.discountCode === discount.companyCode;
            const mappedDiscount: AvailableDiscount = {
                code: discount.companyCode,
                //
                label: discount.name,
                description: discount.description,
                //
                discountDataRequired: discount.requiresData,
                loyaltyDataRequired: discount.requiresLoyalty,
                //
                isSelected,
                isDisabled: false,
            };
            if (isSelected) {
                selectedDiscount = mappedDiscount;
            }
            availableDiscounts.push(mappedDiscount);
        }
        return { selectedDiscount, availableDiscounts };
    };

    private getPricingVehicleTicket = (
        vehicleData: RoutePriceVehicleV2,
        _options: DispatchVehicleOptions | undefined,
        tripDic: RouteTripDictionaryClient,
    ) => {
        const bmState = this.getBmState();
        const vehicleReq = bmState.routePrice.trips[tripDic.index].vehicleAccReq[vehicleData.index];
        // const vehicleTickets = tripDic.vehicleAcc;
        const vehicleDic = tripDic.vehicleCatAccDic[vehicleData.category].accommodationsDic[vehicleReq.accCode];

        let selectedTicket: SelectedTicket = {
            code: vehicleDic.code,
            //
            label: vehicleDic.name,
            description: vehicleDic.description,
            //
            customCancelPolicy: vehicleDic.cancelPolicy,
            customModifyPolicy: vehicleDic.modifyPolicy,
            nonRefundable: vehicleDic.nonRefundable,
            nonAmendable: vehicleDic.nonAmendable,
            applyToOthers: false,
            //
            includedInPrice: undefined,
            isUpgrade: undefined,
            //
            value: this.formatPrice(vehicleDic.price),
        };
        // const availableTickets: SelectTicketEditVehicleItem[] = [];
        // for (const vehicleTicket of vehicleTickets) {
        //     const isSelected = vehicleTicket.code === vehicleReq.accCode;
        //     const mappedTicket: SelectTicketEditVehicleItem = {
        //         code: vehicleTicket.code,
        //         //
        //         label: vehicleTicket.name,
        //         description: vehicleTicket.description,
        //         //
        //         isSelected,
        //         //
        //         customCancelPolicy: vehicleTicket.cancelPolicy,
        //         customModifyPolicy: vehicleTicket.modifyPolicy,
        //         nonRefundable: vehicleTicket.nonRefundable,
        //         nonAmendable: vehicleTicket.nonAmendable,
        //     };
        //     availableTickets.push(mappedTicket);
        // }

        return { selectedTicket };
    };

    private getPricingVehicleDriver = (
        vehicleData: RoutePriceVehicleV2,
        _options: DispatchVehicleOptions | undefined,
        tripDic: RouteTripDictionaryClient,
    ) => {
        const bmState = this.getBmState();
        const vehicleReq = bmState.routePrice.trips[tripDic.index].vehicleAccReq[vehicleData.index];

        const selectedDriver: SelectedDriver = {
            passengerId: vehicleReq.passengerId,
            //
            label: '',
        };

        const availableDrivers: AvailableDriver[] = [];
        for (const passenger of bmState.routePrice.passengers) {
            const isSelected = passenger.id === vehicleReq.passengerId;
            const isDisabled = !this.vehicleIsDriverEligible(passenger, vehicleData.category);

            availableDrivers.push({
                passengerId: passenger.id,
                label: this.passengerGetLabelOrIndex(passenger),
                isSelected,
                isDisabled,
            });
            if (isSelected) {
                selectedDriver.label = this.passengerGetLabelOrIndex(passenger);
            }
        }

        return { selectedDriver, availableDrivers };
    };

    //---------------TRIP_DETAILS---------------//

    private setVehicleDetails = (
        state: ClientBasketState,
        vehicleData: RoutePriceVehicleV2,
        options?: DispatchVehicleOptions,
    ) => {
        if (this.skipSelectiveUpdate(options?.selectiveUpdate, 'tripDetails')) {
            return;
        }
        const bmState = this.getBmState();
        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleData.id);

        const vehicleDetail: ClientStateVehicleTripDetail = {
            vehicleId: vehicleData.id,
            details: {
                title: this.vehicleGetLabelOrIndex(vehicleData),
                subTitle:
                    savedVehicle?.nickName ||
                    savedVehicle?.plateNumber ||
                    this.vehicleGetCategoryLabel(vehicleData.category),
                category: vehicleData.category,
                complete: false,
            },
            //
        };
        const validation: RouteVehicleValidation = {
            ...bmState.basketDataProcessed.vehicles.validation,
            ...bmState.routePrice.vehicleExtraValidation,
        };

        const additional = this.vehicleGetAdditionalDetails(vehicleData, validation);

        const newVehicleDetails: ClientStateVehicleTripDetail = {
            ...vehicleDetail,
            ...additional.vehicleDetails,
        };

        const valid = this.validateVehicleDetails(additional.fieldOptionsArray);

        if (valid) {
            newVehicleDetails.details.complete = true;
        }

        state.details.vehicles[vehicleData.index] = newVehicleDetails;
    };

    private validateVehicleDetails = (fieldInputs: FieldInput[]) => {
        return fieldInputs.every((item) => !item.required);
    };

    private generateVehicleFieldInput = (
        vehicleData: RoutePriceVehicleV2,
        fieldId: VehicleFieldNames,
        options: {
            required?: boolean;
            canBeRemoved?: boolean;
            validation?: keyof RouteVehicleValidation;
            isPricing?: boolean;
        },
    ): FieldInput | undefined => {
        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleData.id);
        const extraVehicleData = vehicleData.extraPriceData;
        const { required, validation, canBeRemoved, isPricing } = options;
        switch (fieldId) {
            //Vehicle
            case 'nickName': {
                const value = extraVehicleData?.nickName ?? savedVehicle?.nickName ?? vehicleData.nickName;
                return {
                    fieldId: 'nickName',
                    type: 'text',
                    label: this.trns('app.basketManager.nickName'),
                    value,
                    disabled: !!extraVehicleData?.nickName || !!savedVehicle?.nickName,
                    valueFromSaved: !!savedVehicle?.nickName,
                    vehicleValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: !value,
                };
            }
            case 'plateNumber': {
                const value = extraVehicleData?.plateNumber ?? savedVehicle?.plateNumber ?? vehicleData.plateNumber;
                return {
                    fieldId: 'plateNumber',
                    type: 'text',
                    label: this.trns('app.basketManager.plateNumber'),
                    value,
                    disabled: !!extraVehicleData?.plateNumber || !!savedVehicle?.plateNumber,
                    valueFromSaved: !!savedVehicle?.plateNumber,
                    vehicleValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: !value,
                };
            }
            case 'height': {
                const value = extraVehicleData?.size?.height || savedVehicle?.size?.height || vehicleData.size?.height;

                return {
                    fieldId: 'height',
                    type: 'number',
                    label: this.trns('app.selectVehicle.height'),
                    placeholder: this.trns('app.selectVehicle.height'),
                    value,
                    disabled: (!!extraVehicleData?.size?.height && !isPricing) || !!savedVehicle?.size?.height,
                    valueFromSaved: !!savedVehicle?.size?.height,
                    valueFromPricing: !!extraVehicleData?.size?.height && !isPricing && !savedVehicle?.size?.height,
                    vehicleValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'length': {
                const value = extraVehicleData?.size?.length || savedVehicle?.size?.length || vehicleData.size?.length;

                return {
                    fieldId: 'length',
                    type: 'number',
                    label: this.trns('app.selectVehicle.length'),
                    placeholder: this.trns('app.selectVehicle.length'),
                    value,
                    disabled: (!!extraVehicleData?.size?.length && !isPricing) || !!savedVehicle?.size?.length,
                    valueFromSaved: !!savedVehicle?.size?.length,
                    valueFromPricing: !!extraVehicleData?.size?.length && !isPricing && !savedVehicle?.size?.length,
                    vehicleValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            default:
                return undefined;
        }
    };

    vehicleGetAdditionalDetails = (
        vehicleData: RoutePriceVehicleV2,
        validation: RouteVehicleValidation | undefined,
        options?: { isPricing?: boolean },
    ) => {
        if (!validation) {
            return {
                vehicleDetails: {},
                fieldOptionsArray: [],
            };
        }
        const bmState = this.getBmState();
        const vehicleExtraValidation = bmState.routePrice.vehicleExtraValidation || {};

        const vehicleDetails: Optional<ClientStateVehicleTripDetail> = {};

        const fieldOptionsArray: FieldInput[] = [];

        if (validation.plateNum && vehicleData.category !== 'bike') {
            const fieldInput = this.generateVehicleFieldInput(vehicleData, 'plateNumber', {
                required: true,
                validation: 'plateNum',
                canBeRemoved: vehicleExtraValidation['plateNum'],
                ...options,
            });
            if (fieldInput) {
                vehicleDetails.plateNumber = fieldInput;
                fieldOptionsArray.push(fieldInput);
            }
        }
        // if (validation.model) {
        //     const fieldInput = this.generateVehicleFieldInput(vehicleData, 'model', {
        //         required: true,
        //         validation: 'model',
        //         canBeRemoved: vehicleExtraValidation['model'],
        //         ...options,
        //     });
        //     if (fieldInput) {
        //         vehicleDetails.model = fieldInput;
        //         fieldOptionsArray.push(fieldInput);
        //     }
        // }
        // if (validation.make) {
        //     const fieldInput = this.generateVehicleFieldInput(vehicleData, 'make', {
        //         required: true,
        //         validation: 'make',
        //         canBeRemoved: vehicleExtraValidation['make'],
        //         ...options,
        //     });
        //     if (fieldInput) {
        //         vehicleDetails.make = fieldInput;
        //         fieldOptionsArray.push(fieldInput);
        //     }
        // }
        if (validation.height) {
            const fieldInput = this.generateVehicleFieldInput(vehicleData, 'height', {
                required: true,
                validation: 'height',
                canBeRemoved: vehicleExtraValidation['height'],
                ...options,
            });
            if (fieldInput) {
                vehicleDetails.height = fieldInput;
                fieldOptionsArray.push(fieldInput);
            }
        }
        if (validation.length) {
            const fieldInput = this.generateVehicleFieldInput(vehicleData, 'length', {
                required: true,
                validation: 'length',
                canBeRemoved: vehicleExtraValidation['length'],
                ...options,
            });
            if (fieldInput) {
                vehicleDetails.length = fieldInput;
                fieldOptionsArray.push(fieldInput);
            }
        }
        return {
            vehicleDetails,
            fieldOptionsArray,
        };
    };
}
