import { ConfigForData } from '@droplet-tech-code/core-common-types';
import {
  BookingRetrieveClient,
  GetPurchaseResponse,
  PurchaseClientGetProviderReservationForPurchaseRequest,
  PurchaseClientGetProviderReservationRequest,
  PurchaseClientGetProviderReservationWithBookingIdRequest,
  PurchaseCompanyContactDetails,
  PurchaseListByCompanyReservationCodeRequest,
  PurchaseListByCustomerIdRequest,
  PurchaseListByDateRequest,
  PurchaseListByEmailRequest,
  PurchaseListByProviderReservationCodeRequest,
  PurchaseListFullResponse,
  PurchaseListResponse,
  PurchasesByCompanyTicketNumberRequest,
} from '@naus-code/naus-admin-types';
import {
  ActivePolicy,
  DefaultCancelPolicy,
  PaginatedListRequest,
} from '@naus-code/naus-client-types';
import isEqual from 'lodash/isEqual';

import { rootApi } from '~/store/redux.utils';
import { mergeAndRemoveDuplicates } from '~/utils/utils.helper';

const base_url = 'purchase';
export const purchaseApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getPurchaseListByEmail: builder.query<
      PurchaseListResponse,
      PurchaseListByEmailRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-email/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'PurchaseListResponse', 'getPurchaseListByEmail'],
    }),

    invalidatePurchaseListByEmail: builder.mutation<
      PurchaseListResponse,
      PurchaseListByEmailRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-email/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            purchaseApi.util.updateQueryData('getPurchaseListByEmail', req, (draft) => {
              draft.isFinal = data.isFinal;
              draft.list = data.list;
            }),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),

    getPurchaseListByCustomer: builder.query<
      PurchaseListResponse,
      PurchaseListByCustomerIdRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-customer-id/`,
        method: 'POST',
        data,
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache.isFinal = newItems.isFinal;
        currentCache.list = mergeAndRemoveDuplicates(currentCache.list, newItems.list);
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      providesTags: ['*', 'PurchaseListResponse', 'getPurchaseListByCustomer'],
    }),

    invalidatePurchaseListByCustomerId: builder.mutation<
      PurchaseListResponse,
      PurchaseListByCustomerIdRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-customer-id/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            purchaseApi.util.updateQueryData(
              'getPurchaseListByCustomer',
              req,
              (draft) => {
                draft.isFinal = data.isFinal;
                draft.list = data.list;
              },
            ),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),
    getPurchaseListByCompanyResCode: builder.query<
      PurchaseListFullResponse,
      PurchaseListByCompanyReservationCodeRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-company-res-code/`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'PurchaseListFullResponse', 'getPurchaseListByCompanyResCode'],
    }),
    getPurchaseListByProviderResCode: builder.query<
      PurchaseListFullResponse,
      PurchaseListByProviderReservationCodeRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-provider-res-code/`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'PurchaseListFullResponse', 'getPurchaseListByProviderResCode'],
    }),
    getPurchasesByCompanyTicketNumberConfig: builder.query<ConfigForData, void>({
      query: (data) => ({
        url: `${base_url}/form-config/get-by-company-ticket-number/`,
        method: 'GET',
        data,
      }),
      providesTags: ['*', 'getPurchasesByCompanyTicketNumberConfig'],
    }),
    getPurchaseListByCompanyTicketNumber: builder.query<
      PurchaseListFullResponse,
      PurchasesByCompanyTicketNumberRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-by-company-ticket-number/`,
        method: 'POST',
        data,
      }),
      providesTags: [
        '*',
        'PurchaseListFullResponse',
        'getPurchaseListByCompanyTicketNumber',
      ],
    }),
    getPurchase: builder.query<GetPurchaseResponse, string>({
      query: (id: string) => ({
        url: `${base_url}/get-purchase/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPurchase'],
    }),

    getPurchaseRetrieveFromProvider: builder.query<
      PurchaseClientGetProviderReservationResponse,
      PurchaseClientGetProviderReservationForPurchaseRequest
    >({
      query: (data) => ({
        url: `${base_url}/retrieve-from-provider/`,
        method: 'POST',
        data,
      }),
      keepUnusedDataFor: 0,
      providesTags: [
        '*',
        'PurchaseClientGetProviderReservationResponse',
        'getPurchaseRetrieveFromProvider',
      ],
    }),
    getPurchaseRetrieveFromProviderRes: builder.query<
      PurchaseClientGetProviderReservationResponse,
      PurchaseClientGetProviderReservationRequest
    >({
      query: (data) => ({
        url: `${base_url}/retrieve-from-provider-res/`,
        method: 'POST',
        data,
      }),
      keepUnusedDataFor: 0,
      providesTags: [
        '*',
        'PurchaseClientGetProviderReservationResponse',
        'getPurchaseRetrieveFromProviderRes',
      ],
    }),

    getPurchasePdf: builder.query<void, string>({
      query: (id) => ({
        url: `${base_url}/get-pdf/${id}`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPurchasePdf'],
    }),

    getPurchasesUpcoming: builder.query<PurchaseListResponse, PaginatedListRequest>({
      query: (data) => ({
        url: `${base_url}/get-upcoming/`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'PurchaseListResponse', 'getPurchasesUpcoming'],
    }),

    invalidateGetPurchasesUpcoming: builder.mutation<
      PurchaseListResponse,
      PaginatedListRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-upcoming/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(req, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            purchaseApi.util.updateQueryData('getPurchasesUpcoming', req, (draft) => {
              draft.isFinal = data.isFinal;
              draft.list = data.list;
            }),
          );
        } catch (err) {
          console.error(err);
        }
      },
    }),
    // @Post('/get-upcoming/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getPurchasesUpcoming(@Body() body: PaginatedListRequest): Promise<PurchaseListResponse> {
    //     return this._purchaseService.getPurchasesUpcoming(body);
    // }

    getPurchaseRetrieveFromProviderWithBookingId: builder.query<
      PurchaseClientGetProviderReservationResponse,
      PurchaseClientGetProviderReservationWithBookingIdRequest
    >({
      query: (data) => ({
        url: `${base_url}/retrieve-from-provider-with-booking-id/`,
        method: 'POST',
        data,
      }),
      keepUnusedDataFor: 0,
      providesTags: ['*', 'getPurchaseRetrieveFromProviderWithBookingId'],
    }),

    // @Get('/form-config/retrieve-from-provider-res/', [applyRoleValidation(['ADMIN', 'SYSTEM'])])
    // getPurchaseRetrieveFromProviderFormConfig(): Promise<ConfigForData> {
    //     return this._purchaseService.getPurchaseRetrieveFromProviderFormConfig();
    // }

    getPurchaseRetrieveFromProviderFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/retrieve-from-provider-res/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPurchaseRetrieveFromProviderFormConfig'],
    }),

    getPurchaseListByDateFormConfig: builder.query<ConfigForData, void>({
      query: () => ({
        url: `${base_url}/form-config/get-list-by-date/`,
        method: 'GET',
      }),
      providesTags: ['*', 'getPurchaseListByDateFormConfig'],
    }),

    getPurchaseListByDate: builder.query<
      PurchaseListFullResponse,
      PurchaseListByDateRequest
    >({
      query: (data) => ({
        url: `${base_url}/get-list-by-date/`,
        method: 'POST',
        data,
      }),
      providesTags: ['*', 'getPurchaseListByDate'],
    }),

    getPurchaseCompanyContactDetails: builder.query<
      PurchaseCompanyContactDetails,
      string
    >({
      query: (id: string) => ({
        url: `${base_url}/get-company-contact-details/${id}`,
        method: 'Get',
      }),
      providesTags: ['*', 'getPurchaseCompanyContactDetails'],
    }),

    // @Get('/get-active-operator-cancel-policy/:id', [applyRoleValidation(['CUSTOMER_SUPPORT'])])
    // getPurchaseActiveOperatorCancelPolicy(
    //     @Params('id') id: string,
    // ): Promise<PurchaseActiveOperatorCancelPolicyResponse> {
    //     return this._purchaseService.getPurchaseActiveOperatorCancelPolicy(id);
    // }
    getPurchaseActiveOperatorCancelPolicy: builder.query<
      PurchaseActiveOperatorCancelPolicyResponse,
      string
    >({
      query: (id: string) => ({
        url: `${base_url}/get-active-operator-cancel-policy/${id}`,
        method: 'Get',
      }),
      providesTags: ['*', 'getPurchaseActiveOperatorCancelPolicy'],
    }),
  }),
});

export interface PurchaseClientGetProviderReservationResponse {
  reservation: BookingRetrieveClient;
  missingDataConfig?: { departureDateTime: string; config: ConfigForData }[];
}
export interface PurchaseActiveOperatorCancelPolicyResponse {
  activePolicy: ActivePolicy<DefaultCancelPolicy> | undefined;
}
