import dayjs from 'dayjs';
import {
    PetSelectPassenger,
    PetSelectPassengerReq,
    PetSelectPet,
    PetSelectPetReq,
    PetSelectWeightItem,
    PetSelectWeightReq,
    PetSelectState,
} from '../types/providers';
import { BasketManagerPrice } from './bm.4.price';
import { KeyVal, RoutePricePetV2, TicketPetCategory, TripPricePetAccommodation } from '@naus-code/naus-client-types';
import { generateRandomString } from '../utils/functions';
import { createError } from '../errors';
import { ClientBasketState } from '../types/clientState';

export class BasketManagerPetsQuotes extends BasketManagerPrice {
    //---------------PET_SELECT---------------//

    getPetAddState = (petId?: string): PetSelectState => {
        let checkPointState: PetSelectState = this.getStartingPetState(undefined);

        if (!petId) {
            return checkPointState;
        }
        checkPointState.petId = petId;

        const petData = this.petGetPetRoutePrice(petId);
        const savedPet = this.petGetSavedDictionaryNoThrow(petData.id);

        checkPointState.pet.selected = {
            id: petData.id,
            label: this.petGetCategoryLabel(petData),
            category: petData.petCategory,
            type: savedPet ? 'saved' : 'guest',
            remove: () => checkPointState,
        };
        // this.appendPetRemoveToSelected(checkPointState, 'pet');

        //Validate to add weight
        checkPointState = this.validatePetSelectState(checkPointState, petData).state;

        if (checkPointState.weight) {
            const { minWeight, maxWeight } = petData;
            if (minWeight === undefined || maxWeight === undefined) {
                return checkPointState;
            }
            const selectedWeight = checkPointState.weight.list.find(
                (weight) => weight.minWeight <= minWeight && maxWeight <= weight.maxWeight,
            );
            if (!selectedWeight) {
                return checkPointState;
            }

            checkPointState.weight.selected = {
                key: selectedWeight.key,
                label: this.petGetWeightLabel(selectedWeight),
                remove: () => checkPointState,
            };
            // this.appendPetRemoveToSelected(checkPointState, 'weight');
        }

        //Validate to add passenger
        checkPointState = this.validatePetSelectState(checkPointState, petData).state;

        if (checkPointState.passenger) {
            const passenger = this.passengerGetPasRoutePrice(petData.passengerId);
            checkPointState.passenger.selected = {
                id: petData.passengerId,
                label: this.passengerGetLabelOrIndex(passenger),
                remove: () => checkPointState,
            };
            // this.appendPetRemoveToSelected(checkPointState, 'passenger');
        }

        return this.validatePetSelectState(checkPointState, petData).state;
    };

    setPetAddStatePet = (petState: PetSelectPetReq) => {
        const { currentState } = petState;
        if (petState.type === 'saved') {
            const savedPet = this.petGetSavedDictionary(petState.id);
            const petSelected: PetSelectPet['selected'] = {
                id: petState.id,
                label: savedPet.name || this.petGetCategoryLabel(savedPet),
                category: savedPet.petCategory,
                type: petState.type,
                remove: () => this.getStartingPetState(undefined),
            };
            currentState.pet.selected = petSelected;
            const validRes = this.validatePetSelectState(currentState);
            return validRes.state;
        }

        const guestPetSelected: PetSelectPet['selected'] = {
            id: petState.id,
            label: this.petGetCategoryLabel({
                petCategory: petState.id as TicketPetCategory,
            }),
            category: petState.id as TicketPetCategory,
            type: petState.type,
            remove: () => this.getStartingPetState(undefined),
        };
        currentState.pet.selected = guestPetSelected;
        const validRes = this.validatePetSelectState(currentState);
        return validRes.state;
    };

    setPetAddStateWeight = (petState: PetSelectWeightReq) => {
        const { currentState } = petState;

        if (!currentState.weight) {
            return this.validatePetSelectState(currentState).state;
        }
        const weightItem = this.getPetWeightItemFromKey(petState.weightKey);
        if (!weightItem) {
            return this.validatePetSelectState(currentState).state;
        }
        currentState.weight.selected = {
            key: weightItem.key,
            label: this.petGetWeightLabel(weightItem),
            remove: () => this.getStartingPetState(undefined),
        };
        const validRes = this.validatePetSelectState(currentState);
        return validRes.state;
    };

    setPetAddStatePassenger = (petState: PetSelectPassengerReq) => {
        const { currentState } = petState;
        const passenger = this.passengerGetPasRoutePrice(petState.passengerId);
        const passengerSelected: PetSelectPassenger['selected'] = {
            id: passenger.id,
            label: this.passengerGetLabelOrIndex(passenger),
            remove: () => this.getStartingPetState(undefined),
        };
        if (!currentState.passenger) {
            return this.validatePetSelectState(currentState).state;
        }
        currentState.passenger.selected = passengerSelected;
        const validRes = this.validatePetSelectState(currentState);
        return validRes.state;
    };

    private petGetWeightLabel = (data: { minWeight: number; maxWeight: number }) => {
        if (data.minWeight === 0) {
            return `<${(data.maxWeight / 100).toFixed(0)} kg`;
        }
        return `${(data.minWeight / 100).toFixed(0)}-${(data.maxWeight / 100).toFixed(0)} kg`;
    };

    private getPetWeightItemFromKey = (weightKey: string) => {
        const weightItem = this.getPetWeightList().find((weight) => weight.key === weightKey);
        return weightItem;
    };

    //---------------PET_SELECT_VALIDATE---------------//
    private getPetWeightList = () => {
        const petWeightList: PetSelectWeightItem[] = [
            {
                key: '0-6',
                minWeight: 0,
                maxWeight: 699,
                label: '',
            },
            {
                key: '6-10',
                minWeight: 700,
                maxWeight: 1000,
                label: '',
            },
        ];
        petWeightList.forEach((weight) => {
            weight.label = this.petGetWeightLabel(weight);
        });
        return petWeightList;
    };

    private getStartingPetState = (existingPet: RoutePricePetV2 | undefined): PetSelectState => {
        const bmState = this.getBmState();
        const accompanyPassenger =
            bmState.routePrice.passengers.length > 1 ? undefined : bmState.routePrice.passengers[0];
        return {
            petId: existingPet?.id,
            pet: {
                accompanyPassengerLabel: accompanyPassenger
                    ? this.passengerGetLabelOrIndex(accompanyPassenger)
                    : undefined,
                guest: [
                    {
                        id: 'cat',
                        category: 'cat',
                        label: this.trns('app.basketManager.cat'),
                    },
                    {
                        id: 'dog',
                        category: 'dog',
                        label: this.trns('app.basketManager.dog'),
                    },
                    {
                        id: 'guide-dog',
                        category: 'guide-dog',
                        label: this.trns('app.basketManager.guideDog'),
                    },
                ],
                saved: bmState.savedPets.map((pet) => ({
                    id: pet.id,
                    category: pet.petCategory,
                    label: pet.name,
                })),
            },
        };
    };

    private validatePetSelectState = (
        petState: PetSelectState,
        existingPet?: RoutePricePetV2,
    ): {
        valid: boolean;
        state: PetSelectState;
    } => {
        const petSelected = petState.pet.selected;

        if (!petSelected) {
            return { valid: false, state: this.getStartingPetState(existingPet) };
        }

        if (petSelected.type === 'saved') {
            return this.validatePetSelectStateSavedPet(petState);
        }
        return this.validatePetSelectStateGuest(petState, existingPet);
    };

    private validatePetSelectStateSavedPet = (petState: PetSelectState) => {
        const pet = petState.pet;
        const petSelected = pet.selected;
        const checkPointState = this.getStartingPetState(undefined);
        checkPointState.petId = petState.petId;

        if (!petSelected) {
            return { valid: false, state: checkPointState };
        }

        const bmState = this.getBmState();
        const petValidation = bmState.basketDataProcessed.pets.validation;

        const savedPet = this.petGetSavedDictionary(petSelected.id);
        const savedPetSelected: PetSelectPet['selected'] = {
            id: petSelected.id,
            label: savedPet.name,
            category: savedPet.petCategory,
            type: petSelected.type,
            remove: () => this.getStartingPetState(undefined),
        };
        checkPointState.pet.selected = savedPetSelected;
        this.appendPetRemoveToSelected(checkPointState, 'pet');

        //---SIZE
        //TODO Check if cat requires weight
        const askForWeight =
            petValidation.weight &&
            (!savedPet.weight || !savedPet.weight_date || dayjs().diff(dayjs(savedPet.weight_date), 'months') > 4);

        if (askForWeight) {
            checkPointState.weight = {
                list: this.getPetWeightList(),
            };
            const sizeSelected = petState.weight?.selected;
            checkPointState.weight.selected = sizeSelected;
            if (!sizeSelected) {
                return { valid: false, state: checkPointState };
            }
            if (checkPointState.weight.selected) {
                const removeState = { ...checkPointState };
                checkPointState.weight.selected.remove = () => {
                    removeState.weight!.selected = undefined;
                    return removeState;
                };
            }
        }

        //---PASSENGER
        const passengers = bmState.routePrice.passengers;
        if (petValidation.passengerLink && passengers.length > 1) {
            checkPointState.passenger = {
                list: passengers.map((pas) => ({
                    passengerId: pas.id,
                    label: this.passengerGetLabelOrIndex(pas),
                })),
            };
            const passengerSelected = petState.passenger?.selected;
            checkPointState.passenger.selected = passengerSelected;
            if (!passengerSelected) {
                return { valid: false, state: checkPointState };
            }
            this.passengerGetPasRoutePrice(passengerSelected.id);

            if (checkPointState.passenger.selected) {
                const removeState = { ...checkPointState };
                checkPointState.passenger.selected.remove = () => {
                    removeState.passenger!.selected = undefined;
                    return removeState;
                };
            }
        }

        checkPointState.readyToComplete = true;
        return { valid: true, state: checkPointState };
    };

    private validatePetSelectStateGuest = (petState: PetSelectState, existingPet: RoutePricePetV2 | undefined) => {
        const pet = petState.pet;
        const petSelected = pet.selected;
        let checkPointState = this.getStartingPetState(existingPet);
        checkPointState.petId = petState.petId;
        if (!petSelected) {
            return { valid: false, state: checkPointState };
        }

        const bmState = this.getBmState();
        const petValidation = bmState.basketDataProcessed.pets.validation;

        const guestPetSelected: PetSelectPet['selected'] = {
            id: petSelected.id,
            label: this.petGetCategoryLabel({
                petCategory: petSelected.category,
            }),
            category: petSelected.category,
            type: petSelected.type,
            remove: () => this.getStartingPetState(existingPet),
        };
        checkPointState.pet.selected = guestPetSelected;
        this.appendPetRemoveToSelected(checkPointState, 'pet');

        if (petValidation.weight) {
            checkPointState.weight = {
                list: this.getPetWeightList(),
            };
            const sizeSelected = petState.weight?.selected;
            checkPointState.weight.selected = sizeSelected;
            if (!sizeSelected) {
                return { valid: false, state: checkPointState };
            }
            this.appendPetRemoveToSelected(checkPointState, 'weight');
        }

        //PASSENGERS
        const passengers = bmState.routePrice.passengers;
        if (petValidation.passengerLink && passengers.length > 1) {
            checkPointState.passenger = {
                list: passengers.map((pas) => ({
                    passengerId: pas.id,
                    label: this.passengerGetLabelOrIndex(pas),
                })),
            };
            const passengerSelected = petState.passenger?.selected;
            checkPointState.passenger.selected = passengerSelected;
            if (!passengerSelected) {
                return { valid: false, state: checkPointState };
            }
            this.passengerGetPasRoutePrice(passengerSelected.id);
            this.appendPetRemoveToSelected(checkPointState, 'passenger');
        }

        checkPointState.readyToComplete = true;
        return { valid: true, state: checkPointState };
    };

    //---------------REMOVE_FUNC---------------//

    private appendPetRemoveToSelected = (
        petState: PetSelectState,
        key: keyof Omit<PetSelectState, 'readyToComplete' | 'petId'>,
    ) => {
        if (petState[key]?.selected) {
            const removeState = { ...petState };
            petState[key]!.selected!.remove = () => {
                removeState[key]!.selected = undefined;
                return removeState;
            };
        }
    };

    //---------------ADD---------------//

    addPet = (petState: PetSelectState, options?: { state?: ClientBasketState }) => {
        const bmState = this.getBmState();
        const { routePrice } = bmState;
        const petIdToReplace = petState.petId;
        const index = petIdToReplace
            ? routePrice.pets.find((pet) => pet.id === petIdToReplace)?.index
            : routePrice.pets.length;

        if (index === undefined) {
            throw createError({ code: 'PET_NOT_FOUND_FOR_ADD' });
        }

        const { valid } = this.validatePetSelectState(petState, undefined);

        if (!valid) {
            return;
        }
        const petValidation = bmState.basketDataProcessed.pets.validation;
        const passengerId =
            routePrice.passengers.length > 1 && petValidation.passengerLink
                ? petState.passenger?.selected?.id
                : routePrice.passengers[0].id;
        if (!passengerId) {
            throw createError({ code: 'PET_PASSENGER_NOT_LINKED' });
        }

        const petSelected = petState.pet.selected;
        if (!petSelected) {
            throw createError({ code: 'PET_DATA_INCOMPLETE' });
        }

        const savedPet = this.petGetSavedDictionaryNoThrow(petSelected.id);

        const petCategory = savedPet ? savedPet.petCategory : petSelected.category;
        //
        const weightItem = petState.weight?.selected?.key
            ? this.getPetWeightItemFromKey(petState.weight?.selected?.key)
            : undefined;
        const petData: RoutePricePetV2 = {
            id: savedPet?.id || petIdToReplace || generateRandomString(),
            index,
            passengerId,
            name: savedPet?.name || this.petGetCategoryLabel({ petCategory }),
            petCategory,
            // identityDocType?: string;
            // identityDocNum?: string;
            minWeight: weightItem?.minWeight,
            maxWeight: weightItem?.maxWeight,
            passportDoc: savedPet?.passportDoc,
            medicalDoc: savedPet?.medicalDoc,
            country: savedPet?.country,
            weight: savedPet?.weight,
            weight_date: savedPet?.weight_date,
        };

        //Refresh Accommodation Dictionaries for new pets (pet cabins to be included)
        this.refreshDictionaries();

        const petReqPerTrip: KeyVal<TripPricePetAccommodation> = {};

        for (let tripIndex = 0; tripIndex < bmState.selectedTrips.length; tripIndex++) {
            const trip = bmState.selectedTrips[tripIndex];
            const dic = this.getTripDictionary(trip.key);
            const categoryDic = dic.petCatAccDic[petCategory];
            const petReq: TripPricePetAccommodation = {
                petId: petData.id,
                petCode: categoryDic.accommodations[0].code,
            };
            petReqPerTrip[trip.key] = petReq;
            bmState.routePrice.trips[tripIndex].petAccReq[petData.index] = petReq;
        }

        if (!petIdToReplace && bmState.unselectedPets.length) {
            bmState.unselectedPets.splice(0, 1);
        }

        this.dispatchBasketWrapper(options?.state, (state) => {
            this.dispatchPet(petData, {
                state,
                petReqPerTrip,
                selectiveUpdate: {
                    quoteOptions: true,
                    pricing: true,
                },
            });
        });
        this.dispatchManager();

        if (bmState.basketDataProcessed.flowConfig.skipTripPricingDetails) {
            this.dispatchPrices();
        }
    };

    removePet = () => {
        const bmState = this.getBmState();
        if (bmState.unselectedPets.length) {
            bmState.unselectedPets.splice(0, 1);
            this.dispatchBasket((state) => {
                state.quoteOptions.pets.unselectedList = [...bmState.unselectedPets];
            });
            this.dispatchManager();
            return;
        }

        if (!bmState.routePrice.pets.length) {
            return;
        }

        const petIndex = bmState.routePrice.pets.length - 1;
        bmState.routePrice.pets.splice(petIndex, 1);

        //Refresh dictionaries if pets removed completely to remove pet cabins in accommodations
        if (!bmState.routePrice.pets.length) {
            this.refreshDictionaries();
        }

        for (let tripIndex = 0; tripIndex < bmState.selectedTrips.length; tripIndex++) {
            bmState.routePrice.trips[tripIndex].petAccReq.splice(petIndex, 1);
        }

        this.dispatchBasket((state) => {
            state.quoteOptions.pets.list.splice(petIndex, 1);
            for (let tripIndex = 0; tripIndex < bmState.routePrice.trips.length; tripIndex++) {
                state.pricing.trips[tripIndex].pets.splice(petIndex, 1);
                state.details.pets.splice(petIndex, 1);
            }
            state.quoteOptions.query = this.getQueryBreakdownFromRoutePrice(bmState.routePrice);
        });
        this.dispatchManager();
        if (bmState.basketDataProcessed.flowConfig.skipTripPricingDetails) {
            this.dispatchPrices();
        }
    };
}
