import {
  PurchaseClientAutoImportRequest,
  TicketClient,
} from '@naus-code/naus-admin-types';

import { purchaseActionApi } from '../../PurchaseActions.api';
import { Modal, useModal } from '@droplet-tech-code/core-elements/module/ui/Modal';
import { ModalImportPurchaseFromProvider } from './ActionPanel.Import';
import {
  ManualModificationProvider,
  useManualStore,
} from '../Modify/Manual/ManualProvider/Modify.ManualTrip.Provider';
import { showToast } from '@droplet-tech-code/core-elements/module/ui/Toast';
import { handleResponse } from '@droplet-tech-code/core-elements/module/utils/error';
import { ManualModifyPurchaseInfo } from '../Modify/Manual/Modify.ManualTripManager';
import {
  createAutoImportPurchases,
  mapPurchaseRetrieveToClient,
  mapTicketToClient,
} from './ActionPanel.Import.utils';
import {
  BookingRetrieve,
  BookingRetrievePurchase,
} from '@naus-code/naus-server-common-types';
import { ConfigForData } from '@droplet-tech-code/core-common-types';
import { purchaseActionManager } from '../Common/ActionPanel.utils';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { useEffect } from 'react';

const ImportPurchaseEditModalKey = 'ImportPurchaseEditModal-Key';

export const useImportPurchaseEditModal = () => {
  const { openModal, closeModal } = useModal();

  return {
    open: (
      body: PurchaseClientAutoImportRequest,
      retrievedBooking: BookingRetrieve,
      _config?: ConfigForData,
    ) => {
      openModal(
        <ImportPurchaseEditContainer body={body} retrievedBooking={retrievedBooking} />,
        {
          key: ImportPurchaseEditModalKey,
          type: 'action-sheet',
        },
      );
    },
    close: () => closeModal(ImportPurchaseEditModalKey),
  };
};

const ImportPurchaseEditContainer = ({
  body,
  retrievedBooking,
}: {
  body: PurchaseClientAutoImportRequest;
  retrievedBooking: BookingRetrieve;
}) => {
  return (
    <ManualModificationProvider>
      <View p="2.5">
        <ImportPurchaseEditModal
          body={body}
          retrievedBooking={retrievedBooking}
          purchase={body.purchase}
        />
      </View>
    </ManualModificationProvider>
  );
};

const ImportPurchaseEditModal = ({
  body,
  purchase,
  retrievedBooking,
}: {
  body: PurchaseClientAutoImportRequest;
  purchase: BookingRetrievePurchase;
  retrievedBooking: BookingRetrieve;
}) => {
  const [selectedPurchase, setSelectedReservation] =
    purchaseActionManager.state.useGlobalState('selectedReservation');

  const [_selectedTickets, setSelectedTickets] =
    purchaseActionManager.state.useGlobalState('selectedTickets');

  const mappedTickets = purchase.tickets
    .map((ticket, index) => mapTicketToClient(ticket, purchase, index + ''))
    .filter((ticket) => !!ticket);

  const ticketIds = mappedTickets.map((ticket) => ticket?._id);

  useEffect(() => {
    setSelectedReservation(purchase.companyReservationCode);
    setSelectedTickets(ticketIds as string[]);
  }, [selectedPurchase]);

  const { closeModal } = useModal();
  const manualModStore = useManualStore();
  const modificationForms = manualModStore((s) => s.modificationForms);

  const [addInfo, { isLoading: isAddingPurchase }] =
    purchaseActionApi.useImportPurchaseFromProviderMutation();
  const purchaseProcessed = mapPurchaseRetrieveToClient(
    purchase,
    mappedTickets as TicketClient[],
  );

  const onConfirm = async () => {
    const purchasesToUpdateData = createAutoImportPurchases(modificationForms, purchase);

    if (!purchasesToUpdateData) {
      showToast('Cannot get purchase form data', { type: 'error' });
      return;
    }

    const { autoUpdatePurchase } = purchasesToUpdateData;

    const response = await addInfo({
      ...body,
      purchase: {
        ...autoUpdatePurchase,
        dateCreated: retrievedBooking.dateCreated,
      },
    });

    handleResponse({
      response,
      onError: () => {
        showToast('Importing failed!', { type: 'error' });
      },
      onSuccess: () => {
        closeModal(ModalImportPurchaseFromProvider);
        closeModal(ImportPurchaseEditModalKey);
      },
    });
    setSelectedReservation('');
    return response;
  };

  return (
    <Modal.Container style={{ maxHeight: 800 }}>
      <Modal.Header
        title="Confirm all details are correct and accurate"
        onPress={() => closeModal(ImportPurchaseEditModalKey)}
      />
      <Modal.Body scroll>
        <ManualModifyPurchaseInfo purchase={purchaseProcessed} defaultShowAllVessels />
      </Modal.Body>
      <Modal.Footer onConfirm={() => onConfirm()} isLoading={isAddingPurchase} />
    </Modal.Container>
  );
};
