import { ButtonInteractionState } from '@droplet-tech-code/core-elements/module/ui/Button';
import { DottedLine } from '@droplet-tech-code/core-elements/module/ui/Divider/Divider';
import { Icon } from '@droplet-tech-code/core-elements/module/ui/Icon';
import {
  PopoverContainer,
  PopoverList,
} from '@droplet-tech-code/core-elements/module/ui/Popover';
import { HStack, VStack } from '@droplet-tech-code/core-elements/module/ui/Stack';
import { Text } from '@droplet-tech-code/core-elements/module/ui/Text';
import { View } from '@droplet-tech-code/core-elements/module/ui/View';
import { dayjs } from '@droplet-tech-code/core-elements/module/utils/utils.date';
import {
  isMobile,
  isNativeMobile,
} from '@droplet-tech-code/core-elements/module/utils/utils.helper';
import { SupportRequestListItemResponse } from '@naus-code/naus-admin-types';
import { SupportStatus } from '@naus-code/naus-client-types';
import { Pressable } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { LiveDateText } from '~/components/DateText';
import { getInitials } from '~/components/Initials';
import { Pill } from '~/components/Pill/Pill';
import {
  displaySupportStatus,
  getColorFromStatus,
} from '~/components/SupportRequest/utils/SupportRequestStatus.utils';
import { AppText } from '~/components/Text';
import { useGetAuthUserRules } from '~/screens/Auth/Auth.Context';
import { getAppTheme } from '~/utils/theme.utils';
import { translate } from '~/utils/translation.utils';

export interface SupportRequestItemComponentProps {
  supportRequest: SupportRequestListItemResponse;
  onPress?: () => void;
  selected?: boolean;
}

export const SupportRequestListItemComponent = ({
  supportRequest,
  onPress,
  selected,
}: SupportRequestItemComponentProps) => {
  const { shading } = getAppTheme();

  return (
    <>
      {isMobile ? (
        <TouchableOpacity onPress={onPress}>
          <View
            br={10}
            b={2}
            bg="white"
            bc="monochrome-extraLight"
            style={[{ overflow: 'hidden' }]}
          >
            <SupportRequestListItemInternalComponent supportRequest={supportRequest} />
          </View>
        </TouchableOpacity>
      ) : (
        <Pressable onPress={onPress}>
          {({ hovered }: ButtonInteractionState) => {
            return (
              <View
                br={10}
                bg={hovered ? 'primary-base' : 'white'}
                bc={selected ? 'primary-mid' : hovered ? 'primary-extraLight' : 'white'}
                b={2}
                style={[{ overflow: 'hidden' }, shading.light]}
              >
                <SupportRequestListItemInternalComponent
                  supportRequest={supportRequest}
                />
              </View>
            );
          }}
        </Pressable>
      )}
    </>
  );
};

const SupportRequestListItemInternalComponent = ({
  supportRequest,
}: {
  supportRequest: SupportRequestListItemResponse;
}) => {
  const canViewPendingRefund = useGetAuthUserRules('canCreatePendingRefund');
  const {
    title,
    status,
    c_name,
    c_email,
    dateRegarding,
    dateUrgency,
    dateCreated,
    ref,
    requiresAttention,
    hasRateBeenSent,
    hasRefundBeenProcessed,
    color,
    hasCustomerRated,
    priority,
  } = supportRequest;
  const statusColor = getColorFromStatus(status as SupportStatus);
  const isRecent = dayjs().diff(dayjs(dateCreated), 'minute') < 30;
  const showPendingRefund = canViewPendingRefund && supportRequest.hasPendingRefund;

  const hasSubsection =
    supportRequest.dateCustomerLastSeen ||
    isRecent ||
    showPendingRefund ||
    supportRequest.hasPendingPayment;

  return (
    <VStack flex={1}>
      <HStack flex={1}>
        <View style={{ backgroundColor: color, width: 15, height: '100%' }} />
        <VStack px="2.5" py="1.5" space="1" flex={1}>
          <HStack flex={1} style={{ flexWrap: 'wrap' }}>
            <VStack flex={1}>
              <Text.Small color="monochrome-extraDark">{`${ref}`}</Text.Small>
              <LiveDateText
                dateTime={dateCreated}
                formatOption="lifetimeDependent"
                type="small"
                color="monochrome-extraDark"
              />
            </VStack>
            <AssignedUserMeta supportRequest={supportRequest} offsetY={0} />
          </HStack>
          <HStack justify="flex-end" space="2.5">
            <Text.Body2Medium flex={1}>{title}</Text.Body2Medium>
            {hasRefundBeenProcessed && (
              <Icon icon="custom-refundable" size="medium" color="monochrome-extraDark" />
            )}
            {hasRateBeenSent && !hasCustomerRated && (
              <Icon icon="rating" size="medium" color={'monochrome-mid'} />
            )}
            {hasCustomerRated && <Icon icon="rating" size="medium" color="success" />}
          </HStack>
        </VStack>
        {priority && (
          <View p="2.5" align="flex-start" style={{ height: '100%' }}>
            <Pill color="error" bg="error-base">
              <AppText.Body2Medium color="error">{priority}</AppText.Body2Medium>
            </Pill>
          </View>
        )}
        <HStack
          px="2.5"
          py="1.5"
          bg={statusColor}
          justify="center"
          align="center"
          style={{ alignSelf: 'stretch', width: 110 }}
        >
          <VStack justify="center" align="center">
            <HStack space="1">
              <AppText.MiscTag bold color="black" style={{ letterSpacing: 1.2 }}>
                {displaySupportStatus(status as SupportStatus).toLocaleUpperCase()}
              </AppText.MiscTag>
              {requiresAttention && <Icon icon="warning" color="error" size="large" />}
            </HStack>
          </VStack>
        </HStack>
      </HStack>
      <VStack p="2.5" space="2.5">
        <HStack flex={1}>
          <Text.Small flex={1} color="monochrome-mid">
            {translate('supportRequests.customer')}
          </Text.Small>
          <Text.Small
            align="right"
            color="monochrome-dark"
            style={[isNativeMobile && { maxWidth: 270 }]}
          >
            {c_name && c_email ? `${c_name}, ${c_email}` : 'N/A'}
          </Text.Small>
        </HStack>
        {dateUrgency ? (
          <HStack>
            <Text.Small flex={1} color="monochrome-mid" align="left">
              {translate('supportRequests.actionBy')}
            </Text.Small>
            <LiveDateText
              dateTime={dateUrgency}
              indexTextStyles={[undefined, { bold: true }]}
              formatOption="fullDate&duration"
              type="small"
              color="monochrome-dark"
              align="left"
            />
          </HStack>
        ) : null}
        {dateRegarding ? (
          <HStack>
            <Text.Small flex={1} color="monochrome-mid">
              {translate('supportRequests.dateRegarding')}
            </Text.Small>
            <LiveDateText
              dateTime={dateRegarding}
              indexTextStyles={[undefined, { bold: true }]}
              formatOption="fullDate&duration"
              type="small"
              color="monochrome-dark"
              align="right"
            />
          </HStack>
        ) : null}
        {hasSubsection ? (
          <VStack space="2.5">
            <DottedLine />
            <HStack space="1">
              {isRecent ? (
                <HStack>
                  <View style={{ backgroundColor: '#7d56c7' }} p="1" br={6}>
                    <Text.Small color="white" bold>
                      {translate('supportRequests.recent')}
                    </Text.Small>
                  </View>
                </HStack>
              ) : null}
              {supportRequest.hasPendingPayment ? (
                <HStack>
                  <View bg="secondary-light" p="1" br={6}>
                    <Text.Small color="white" bold>
                      {translate('supportRequests.pendingPayment')}
                    </Text.Small>
                  </View>
                </HStack>
              ) : null}
              {showPendingRefund ? (
                <HStack>
                  <View bg="primary-light" p="1" br={6}>
                    <Text.Small color="white" bold>
                      {translate('supportRequests.pendingRefund')}
                    </Text.Small>
                  </View>
                </HStack>
              ) : null}
              <View flex={1} />
              {supportRequest.dateCustomerLastSeen ? (
                <HStack space="1">
                  <Icon icon="eye" size="small" color="monochrome-extraDark" />
                  <LiveDateText
                    align="center"
                    dateTime={supportRequest.dateCustomerLastSeen}
                    formatOption="lifetimeDependent&UnderDay"
                    type="small"
                    color="monochrome-extraDark"
                    defaultText={'Seen'}
                  />
                </HStack>
              ) : null}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    </VStack>
  );
};

const stringToColour = (str: string, opacity?: number) => {
  let hash = 0;
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, '0');
  }
  if (opacity) {
    const hexOpacity = Math.floor(255 * (opacity / 100));
    const hexString = hexOpacity > 100 ? 'ff' : hexOpacity.toString(16);
    colour += hexString;
  }

  return colour;
};

const AssignedUserMeta = ({
  supportRequest,
  offsetY,
}: {
  supportRequest: SupportRequestListItemResponse;
  offsetY: number;
}) => {
  const agentName = supportRequest.adminUserName;
  if (!agentName) {
    return null;
  }
  const agentColour = agentName ? stringToColour(agentName, 20) : 'white';
  const initials = getInitials(agentName);

  return (
    <PopoverContainer
      width={200}
      maxHeight={320}
      offsetY={offsetY}
      // hoverEnable
      Popover={
        <PopoverList
          list={[
            {
              item: <Text.Small>{agentName}</Text.Small>,
            },
          ]}
        />
      }
    >
      {() => {
        return (
          <HStack p="1.5" br={50} style={{ backgroundColor: agentColour }}>
            <Text.ExtraSmall>{initials}</Text.ExtraSmall>
          </HStack>
        );
      }}
    </PopoverContainer>
  );
};
